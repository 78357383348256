import React from 'react';
import { Route, Routes } from 'react-router-dom';
import QnaList from '../../components/Community/Qna/QnaList';
import QnaDetail from '../../components/Community/Qna/QnaDetail';
import QnaForm from '../../components/Community/Qna/QnaForm';

export default function QnaPage() {
  return (
    <Routes>
      <Route path="/*" element={<QnaList />} />
      <Route path="/:id" element={<QnaDetail />} />
    </Routes>
  );
}
