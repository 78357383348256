import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Image,
  Pagination, message,
} from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as shoppingAPI from "../../../apis/shopping";
import * as systemAPI from "../../../apis/system";

import {dateToLongString, dateToString, getDiscountRate} from "../../../utils/common";
import {getOrder, getProduct} from "../../../apis/shopping";

/*
회원번호, 닉네임, 회원등급, 가입이메일, 가입채널, 가입년월일시, 최종로그인년월일시,
마케팅동의여부, 마케팅동의년월일시, 생년월일, 연령대, 가입경로, 섭취영양제수,
섭취목적, 복용조합점수, 복용조합영양제수, 복용조합영양소수, 복용리스트(섭취영양제종류),
최근커뮤니티작성내역(표 형태,
    최대 10개 노출 : 커뮤니티종류(리뷰, 꿀팁, 꿀조합), 작성일, 최종수정일, 제목),
최근찜목록(표 형태, 최대 10개 노출)
*/
export default function InfoDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchOrder = () => {
    shoppingAPI.getOrder({ id: params?.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setItem(res.data.data);

        const request = {
          page: current,
          size: 10,
          userEmail: res.data.data.id,
        };

        systemAPI.getPoints(request).then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setPoints(
              res.data.data?.map((r) => {
                return {
                  ...r,
                  key: r.id,
                };
              })
            );
            setLoading(false);
          }
        });
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchOrder();
  }, [current]);

  const pointColumns = [
    {
      title: "내용",
      dataIndex: "desc",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "포인트",
      dataIndex: "point",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  const editState = (id, state) => {
    shoppingAPI.editOrder({
      id,
      state
    }).then((res) => {
      if (res.data.linusCode === 1) {
        message.success("성공적으로 수정되었습니다.");
        setItem(res?.data?.data)
      } else {
        alert(res.data.data.msg);
      }
    })
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="주문 정보 조회" />
      <FlexRowReverseContainer>
        {/* <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/info/edit/${params.id}`);
          }}
        >
          수정
        </Button> */}
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/order/`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && <Skeleton active />}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="주문 ID">{params?.id}</Descriptions.Item>
            <Descriptions.Item label="주문 번호">
              {item.number ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="주문 날짜">
              {dateToLongString(new Date(item?.createdDate))}
            </Descriptions.Item>
            <Descriptions.Item label="주문 상품명">
              {item.productPackages?.map(elem => elem?.product?.name).toString() ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="주문 상품 Id 목록">
              {item.productPackages?.map(elem => elem?.product?.id).toString() ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="주문자명">
              {item?.user?.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="배송지 정보">
              {item?.addressBook?.zipCode} {item?.addressBook?.address} {item?.addressBook?.addressDetail}
            </Descriptions.Item>
            <Descriptions.Item label="결제 금액">
              {item?.payments?.length ? item?.payments[0]?.cash : '0'}
            </Descriptions.Item>
            <Descriptions.Item label="적립포인트">
              {item.earnedPoint ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="주문상태">
              {item?.state}
              <div style={{marginTop: 20}}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    editState(item?.id, '결제 완료')
                    return false
                  }}
                >
                  결제 완료
                </Button>
                <Button
                  style={{marginLeft: 15}}
                  onClick={(e) => {
                    e.preventDefault();
                    editState(item?.id, '취소 중')
                    return false
                  }}
                >
                  취소 중
                </Button>
                <Button
                  style={{marginLeft: 15}}
                  onClick={(e) => {
                    e.preventDefault();
                    editState(item?.id, '취소 완료')
                  }}
                >
                  취소 완료
                </Button>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
