import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Image,
  Pagination, message,
} from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as shoppingAPI from "../../../apis/shopping";
import * as systemAPI from "../../../apis/system";

import {dateToLongString, dateToString, getDiscountRate} from "../../../utils/common";
import {editInquiry, getInquiry, getOrder, getProduct} from "../../../apis/shopping";
import {CloseOutlined} from "@ant-design/icons";

/*
회원번호, 닉네임, 회원등급, 가입이메일, 가입채널, 가입년월일시, 최종로그인년월일시,
마케팅동의여부, 마케팅동의년월일시, 생년월일, 연령대, 가입경로, 섭취영양제수,
섭취목적, 복용조합점수, 복용조합영양제수, 복용조합영양소수, 복용리스트(섭취영양제종류),
최근커뮤니티작성내역(표 형태,
    최대 10개 노출 : 커뮤니티종류(리뷰, 꿀팁, 꿀조합), 작성일, 최종수정일, 제목),
최근찜목록(표 형태, 최대 10개 노출)
*/
export default function InfoDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );

  const [answer, setAnswer] = useState('')

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchItem = () => {
    shoppingAPI.getInquiry({ id: params?.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setItem(res.data.data);

        const request = {
          page: current,
          size: 10,
          userEmail: res.data.data.id,
        };

        systemAPI.getPoints(request).then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setPoints(
              res.data.data?.map((r) => {
                return {
                  ...r,
                  key: r.id,
                };
              })
            );
            setLoading(false);
          }
        });
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchItem();
  }, [current]);

  const pointColumns = [
    {
      title: "내용",
      dataIndex: "desc",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "포인트",
      dataIndex: "point",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  const sendAnswer = () => {
    if (window.confirm('문의 답변 완료 시 고객에게 답변 내용이 이메일 발송 됩니다. 정말 완료하시겠습니까?')) {
      shoppingAPI.editInquiry({
        id: item?.id,
        answer
      }).then(res => {
        if (res?.data?.linusCode === 1) {
          message.success("성공적으로 답변되었습니다.");
          fetchItem()
        }
      })
    }
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="문의 정보 조회" />
      <FlexRowReverseContainer>
        {/* <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/info/edit/${params.id}`);
          }}
        >
          수정
        </Button> */}
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/inquiry/`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && <Skeleton active />}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="문의 ID">{params?.id}</Descriptions.Item>
            <Descriptions.Item label="문의 상품명">
              {item.product?.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="문의자 명">
              {item.user?.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="문의자 연락처">
              {item.phone ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="문의자 이메일">
              {item.email ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="문의 내용">
              {item?.content ?? "-"}
            </Descriptions.Item>
            {item?.answer?.length ?
              <Descriptions.Item label="답변 내용">
                {item?.answer ?? "-"}
              </Descriptions.Item>
              : null
            }
          </Descriptions>
        </>
      )}
      {item?.answer?.length ? null :
        <>
          <Descriptions
            labelStyle={{fontWeight: 800}}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="답변 남기기">
              <textarea onChange={({target}) => setAnswer(target?.value)}
                        style={{width: 600, height: 300, borderColor: 'green'}} placeholder={'답변을 남겨 주세요.'}/>
              <Button
                htmlType="submit"
                onClick={(e) => {
                  e.preventDefault();
                  sendAnswer()
                }}
                style={{margin: "0 10px"}}
              >
                답변 저장
              </Button>
            </Descriptions.Item>
          </Descriptions>
        </>
      }
    </div>
  );
}
