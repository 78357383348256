import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReportList from '../../components/Community/Report/ReportList';
import ProductList from "../../components/Shopping/Product/ProductList";
import InfoDetail from "../../components/Shopping/Product/InfoDetail";

export default function ProductPage() {
  return (
    <Routes>
      <Route path="/" element={<ProductList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
