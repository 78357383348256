import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from '../../components/User/Info/InfoDetail';
import ResignUserList from '../../components/User/ResignUser/ResignUserList';

export default function ResignUserPage() {
  return (
    <Routes>
      <Route path="/*" element={<ResignUserList />} />
      <Route path="/:id" element={<InfoDetail />} />
    </Routes>
  );
}
