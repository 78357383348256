import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Image,
  Pagination,
} from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as userAPI from "../../../apis/user";
import * as systemAPI from "../../../apis/system";

import { dateToString } from "../../../utils/common";

/*
회원번호, 닉네임, 회원등급, 가입이메일, 가입채널, 가입년월일시, 최종로그인년월일시,
마케팅동의여부, 마케팅동의년월일시, 생년월일, 연령대, 가입경로, 섭취영양제수,
섭취목적, 복용조합점수, 복용조합영양제수, 복용조합영양소수, 복용리스트(섭취영양제종류),
최근커뮤니티작성내역(표 형태,
    최대 10개 노출 : 커뮤니티종류(리뷰, 꿀팁, 꿀조합), 작성일, 최종수정일, 제목),
최근찜목록(표 형태, 최대 10개 노출)
*/
export default function InfoDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchUsers = () => {
    userAPI.getUser({ id: params.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setUser(res.data.data);

        const request = {
          page: current,
          size: 10,
          userEmail: res.data.data.id,
        };

        systemAPI.getPoints(request).then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setPoints(
              res.data.data?.map((r) => {
                return {
                  ...r,
                  key: r.id,
                };
              })
            );
            setLoading(false);
          }
        });
      }else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [current]);

  const pointColumns = [
    {
      title: "내용",
      dataIndex: "desc",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "포인트",
      dataIndex: "point",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  const onDelete = () => {
    if (window.confirm('삭제된 파트너사 정보는 되돌릴 수 없습니다. 선택한 파트너사 정보를 정말 삭제하시겠습니까?')){
      userAPI.deleteUser({id: params.id}).then(res => {
        navigate('/user/partner')
      })
    }
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="파트너 정보 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onDelete()
          }}
        >
          삭제
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/partner/edit/${params.id}`);
          }}
        >
          수정
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/partner/`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && <Skeleton active />}
      {!loading && (
        <>
          <Descriptions bordered={true} labelStyle={{ fontWeight: 800 }}>
            <Descriptions.Item label="회원 번호">{params.id}</Descriptions.Item>
            <Descriptions.Item label="업체명">
              {user.companyName ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="사업자명">
              {user.companyHolderName ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="사업자번호">
              {user.companyNumber ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="담당부서">
              {user.department ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="담당자명">
              {user.manager ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="담당자 전화번호">
              {user.managerPhone ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="담당자 이메일">
              {user.managerEmail ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="회사 주소">
              {user.companyAddress ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="국가">
              {user.nation ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="지역">
              {user.province ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="회사 대표 전화번호">
              {user.representPhone ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="사이트 url">
              {user.siteUrl ?? "-"}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
