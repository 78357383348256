import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Segmented,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { Option } from "antd/lib/mentions";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";

export const List = () => {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [pushAlarms, setPushAlarms] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [startCreatedDate, setStartCreatedDate] = useState(null);
  const [endCreatedDate, setEndCreatedDate] = useState(null);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState("전체");
  const [type, setType] = useState("페스티벌");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChangeType = (newVal) => {
    setType(newVal);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [form] = Form.useForm();
  const [dateForm] = Form.useForm();

  const searchQuery = Form.useWatch("searchQuery", form);
  const searchKeyword = Form.useWatch("searchKeyword", form);

  const fetchPushAlarms = () => {
    const request = {
      page: current,
      size: 10,
      category: filter === "전체" ? null : filter,
      isFestival: type === "전체" ? null : type === "페스티벌" ? 1 : 2,
      startCreatedDate,
      endCreatedDate,
    };
    request[`${searchQuery}`] = searchKeyword;
    request[`${searchItem.searchQuery}`] = searchItem.searchKeyword;

    systemAPI.getPushAlarms(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setPushAlarms(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
              regTime: dateToString(r.regTime),
              last_login: dateToString(r.last_login),
            };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchPushAlarms();
  }, [
    current,
    filter,
    startCreatedDate,
    endCreatedDate,
    searchQuery,
    searchKeyword,
    type,
  ]);

  const onSearch = (e) => {
    if (!searchQuery) {
      message.error("검색조건을 선택해주세요.");
      return;
    }

    if (!searchKeyword) {
      message.error("검색 키워드를 입력해주세요.");
      return;
    }

    const newSearchItem = {
      ...searchItem,
      searchQuery: searchQuery,
      searchKeyword: searchKeyword,
    };
    setSearchItem(newSearchItem);

    setCurrent(1);
    localStorage.setItem("page", 1);

    setLoading(true);
    fetchPushAlarms();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // 푸시알람번호, 닉네임, 가입채널, 가입년월일시, 최종로그인년월일시, 마케팅동의여부
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "제목",
      dataIndex: "title",
      width: "15%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "내용",
      dataIndex: "content",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "알람종류",
      dataIndex: "category",
      width: "8%",
    },
    {
      title: "예약일시",
      dataIndex: "reservationTime",
      width: "12%",
      render: (val) => {
        return val ? dateToString(val) : "-";
      },
    },
    {
      title: "등록일시",
      dataIndex: "createdDate",
      width: "12%",
      render: (val) => {
        return val ? dateToString(val) : "-";
      },
    },
    {
      title: "푸시발송일시",
      dataIndex: "sentTime",
      width: "12%",
      render: (val) => {
        return val ? dateToString(val) : "-";
      },
    },
  ];

  const searchQueries = [
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "본문",
      dataIndex: "content",
    },
  ];

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onShowDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalVisible(true);
  };

  const onEdit = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    if (selectedItems[0].category === "실시간 발송") {
      message.error("실시간 발송 푸시는 수정이 불가능합니다.");
      return;
    }

    if (type === "일반") {
      navigate(`/system/push-alarm/edit/${selectedItems[0].id}`);
      return;
    }

    navigate(`/system/push-alarm/edit/${selectedItems[0].id}`);
  };

  const onCreate = (e) => {
    e.preventDefault();

    navigate(`/system/push-alarm/new`);
  };

  const onDelete = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    systemAPI
      .deletePushAlarms({ selectedItems: selectedItems?.map((i) => i.id) })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchPushAlarms();
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <PageHeader
        className="site-page-header"
        title="푸시알람 목록"
        subTitle={`총 ${total}개의 푸시알람이 존재합니다.`}
      />
      <FlexRowContainer style={{ marginTop: "30px", marginBottom: "20px" }}>
        <Button
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            const newSearchItem = {
              ...searchItem,
              searchQuery: null,
              searchKeyword: null,
            };
            setSearchItem(newSearchItem);

            setCurrent(1);
            localStorage.setItem("page", 1);
            setFilter("전체");
            setStartCreatedDate(null);
            setEndCreatedDate(null);
            setType("페스티벌");
            form.setFieldValue("searchQuery", null);
            form.setFieldValue("searchKeyword", null);
          }}
        >
          검색 조건 초기화
        </Button>
      </FlexRowContainer>
      <Form form={form} onFinish={onSearch}>
        <FlexRowContainer style={{ marginBottom: "30px" }}>
          <Segmented
            options={["페스티벌", "일반"]}
            value={type}
            onChange={onChangeType}
          />
        </FlexRowContainer>
        <FlexRowContainer style={{ marginTop: "30px" }}>
          <FlexRowQuarterContainer>
            <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>
              <Select placeholder="검색조건">
                {searchQueries?.map((c) => {
                  return (
                    <Select.Option key={c.dataIndex} value={c.dataIndex}>
                      {c.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchKeyword"
              initialValue={searchItem.searchKeyword}
              style={{ width: "300px" }}
            >
              <Input placeholder="검색 키워드를 입력하세요" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              />
            </Form.Item>
          </FlexRowQuarterContainer>
        </FlexRowContainer>
      </Form>
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["전체", "예약 발송", "실시간 발송"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      <FlexRowReverseContainer>
        {/* <Button danger onClick={onShowDeleteModal}>
          선택 삭제
        </Button> */}
        <Button style={{ margin: "0 10px" }} onClick={onEdit}>
          선택 수정
        </Button>
        {type === "일반" && (
          <Button type="primary" onClick={onCreate}>
            추가
          </Button>
        )}
      </FlexRowReverseContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={pushAlarms}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/system/push-alarm/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
};
