export const dateToString = (str) => {
  if (str === null || str === undefined || str.length === 0) {
    return null;
  }

  const dateFromStr = new Date(str);

  const date = new Date(+dateFromStr + 3240 * 10000)
    .toISOString()
    .split('T')[0];

  const time = dateFromStr.toTimeString().split(' ')[0];

  return `${date} ${time}`;
};

export const dateToCompactString = (d) => {
  if (d === null || d === undefined || d.length === 0) {
    return null;
  }
  const date = new Date(+d + 3240 * 10000).toISOString().split('T')[0];

  return `${date}`;
};

export const dateToLongString = (d) => {
  if (d === null || d === undefined || d.length === 0) {
    return null;
  }
  const date = new Date(+d + 3240 * 10000).toISOString();

  return `${date}`;
};

export const startOfMonth = (d) => {
  if (d === null || d === undefined || d.length === 0) {
    return null;
  }
  const date = new Date(d.getFullYear(), d.getMonth(), 2)
    .toISOString()
    .split('T')[0];

  return `${date}`;
};

export const endOfMonth = (d) => {
  if (d === null || d === undefined || d.length === 0) {
    return null;
  }

  const date = new Date(d.getFullYear(), d.getMonth() + 1, 1)
    .toISOString()
    .split('T')[0];

  return `${date}`;
};

export const startOfYear = (d) => {
  if (d === null || d === undefined || d.length === 0) {
    return null;
  }
  const date = new Date(d.getFullYear(), 0, 2).toISOString().split('T')[0];

  return `${date}`;
};

export const endOfYear = (d) => {
  if (d === null || d === undefined || d.length === 0) {
    return null;
  }

  const date = new Date(d.getFullYear(), 12, 1).toISOString().split('T')[0];

  return `${date}`;
};

export const getFileNameFromURL = (url) => {
  if (!url) return;

  let parsed = url?.split('/');
  return parsed[parsed?.length - 1];
};

export const getImageListForUploadFromFlatArr = (arr) => {
  return arr?.map((i) => {
    return {
      ...i,
      name: getFileNameFromURL(i),
      url: i,
      uid: i,
      status: 'success',
    };
  });
};

export const getImageListForUpload = (arr) => {
  return arr?.map((i) => {
    return {
      ...i,
      name: getFileNameFromURL(i.image),
      url: i.image,
      uid: i.id,
      status: 'success',
    };
  });
};

export const getImageListForUploadFromURL = (url) => {
  if (!url) return null;
  return {
    name: getFileNameFromURL(url),
    url: url,
    uid: url,
    status: 'success',
  };
};

export function debounce(func, wait, immediate = false) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}


export const getDiscountRate = (originalPrice, discountedPrice) => {
  let original = originalPrice;
  let discounted = discountedPrice;

  if (typeof original === 'string') {
    original = original.replace(',', '');
  }

  if (typeof discounted === 'string') {
    discounted = discounted.replace(',', '');
  }

  return (((Number(original) - Number(discounted)) / Number(original)) * 100).toFixed(2);
};
