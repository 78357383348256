import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  Radio,
  Checkbox,
} from "antd";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export default function Forms({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [pushAlarm, setPushAlarm] = useState({ category: "실시간 발송" });

  const [image, setImage] = useState(null);

  const systemPushAlarm = () => {
    systemAPI
      .getPushAlarm({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setPushAlarm(res.data.data);
          setLoading(false);
        }else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      systemPushAlarm();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      systemAPI
        .editPushAlarm({
          ...pushAlarm,
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 수정되었습니다.");
            navigate(`/system/push-alarm/${res.data.data.id}`);
          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      systemAPI
        .createPushAlarm({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 등록되었습니다.");
            navigate(`/system/push-alarm/${res.data.data.id}`);
          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const onUpload = (image) => {
    setImage(image);
  };

  const category = Form.useWatch("category", form);

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`푸시알람 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={pushAlarm}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              {pushAlarm?.festival && (
                <>
                  <Form.Item
                    label=""
                    name="isFestivalLikeUser"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox>좋아요한 멤버</Checkbox>
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="isFestivalPlayGroupUser"
                    valuePropName="checked"
                  >
                    <Checkbox>페스티벌 모임</Checkbox>
                  </Form.Item>
                </>
              )}
              <Form.Item label="발송 유형" name="category">
                <Radio.Group>
                  {["실시간 발송", "예약 발송"].map((i) => (
                    <Radio value={i} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              {category === "예약 발송" && (
                <Form.Item label="예약 시간" name="reservationTime">
                  <input type={"datetime-local"} />
                </Form.Item>
              )}
              <Form.Item
                name="title"
                label="제목"
                rules={[
                  {
                    required: true,
                    message: "제목을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="content" label="글 내용">
                <TextArea rows={3} placeholder="글 내용을 입력해주세요." />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/system/push-alarm`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
}
