import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from "../../components/Shopping/Search/InfoDetail";
import SearchList from "../../components/Shopping/Search/SearchList";

export default function SearchPage() {
  return (
    <Routes>
      <Route path="/" element={<SearchList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
