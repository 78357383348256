import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from "../../components/Shopping/Banner/InfoDetail";
import BannerList from "../../components/Shopping/Banner/BannerList";

export default function BannerPage() {
  return (
    <Routes>
      <Route path="/" element={<BannerList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
