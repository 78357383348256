import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EventList from '../../components/Etc/Event/EventList';
import EventDetail from '../../components/Etc/Event/EventDetail';
import EventForm from '../../components/Etc/Event/EventForm';

export default function EventPage() {
  return (
    <Routes>
      <Route path="/*" element={<EventList />} />
      <Route path="/:id" element={<EventDetail />} />
      <Route path="/edit/:id" element={<EventForm isEdit={true} />} />
      <Route path="/new" element={<EventForm isEdit={false} />} />
    </Routes>
  );
}
