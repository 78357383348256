import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { List } from '../../components/System/Point/List';

export default function PointPage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
    </Routes>
  );
}