import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { InfoList } from '../../components/User/PartnerUserInfo/InfoList';
import InfoDetail from '../../components/User/PartnerUserInfo/InfoDetail';

export default function PartnerUserInfoPage() {
  return (
    <Routes>
      <Route path="/*" element={<InfoList />} />
      <Route path="/:id" element={<InfoDetail />} />
    </Routes>
  );
}
