import client from "./client";

/* 인증 */

export const authCheck = () =>
  client.post("/user/token/verify/", {
    accessToken: localStorage.getItem("accessToken"),
  });

/* 로그인 */

export const login = ({ userId, password }) =>
  client.post("/user/email/login/", {
    email: userId,
    password,
  });

/* 회원 정보 조회 */

export const getUser = ({ id }) => client.get(`/user/${id}/`);

export const getMainUser = () =>
  client.get(`/user/`, {
    params: {
      isMain: 1,
    },
  });

export const getUsers = ({
  page,
  size,
  name,
  gender,
  nickName,
  snsType,
  isDeleted,
  createdDate,
  deletedDate,
  startCreatedDate,
  endCreatedDate,
  startDeletedDate,
  endDeletedDate,
  isPartner,
  isStaff,
 companyName,
 companyNumber,
}) =>
  client.get("/user/", {
    params: {
      page: page,
      size: size,
      name,
      gender,
      nickName,
      snsType,
      isDeleted,
      createdDate,
      deletedDate,
      startCreatedDate,
      endCreatedDate,
      startDeletedDate,
      endDeletedDate,
      isPartner,
      isStaff,
      companyName,
      companyNumber,
    },
  });

export const createUser = ({
                           ageGroup,
                           agreeAd,
                           agreeAdTime,
                           birth,
                           email,
                           id,
                           last_login,
                           nickName,
                           regTime,
                           snsType,
                           type,
                           phone,
                           deletedDate,
                           isPartner,
                           name,
                             password,
                             is_superuser,
                            is_staff,
                         }) =>
  client.post(`/user/`, {
    ageGroup,
    agreeAd,
    agreeAdTime,
    birth,
    email,
    id,
    last_login,
    nickName,
    regTime,
    snsType,
    type,
    phone,
    deletedDate,
    isPartner,
    name,
    password,
    is_superuser,
    is_staff
  });

export const editUser = ({
  ageGroup,
  agreeAd,
  agreeAdTime,
  birth,
  email,
  id,
  last_login,
  nickName,
  regTime,
  snsType,
  type,
  phone,
  deletedDate,
   isPartner,
   name,
  password,
 is_superuser,
 is_staff,
  companyName,
 companyHolderName,
 companyNumber,
 department,
 manager,
 managerPhone,
 managerEmail,
 companyAddress,
 nation,
 province,
 representPhone,
 siteUrl
}) =>
  client.put(`/user/${id}/`, {
    ageGroup,
    agreeAd,
    agreeAdTime,
    birth,
    email,
    id,
    last_login,
    nickName,
    regTime,
    snsType,
    type,
    phone,
    deletedDate,
    isPartner,
    name,
    password,
    is_superuser,
    is_staff,
    companyName,
    companyHolderName,
    companyNumber,
    department,
    manager,
    managerPhone,
    managerEmail,
    companyAddress,
    nation,
    province,
    representPhone,
    siteUrl
  });

export const deleteUser = ({ id }) => client.delete(`/user/${id}/`);

export const deleteUsers = ({ selectedItems, resignReason }) =>
  client.delete(`/user/?resignReason=${resignReason}`, {
    data: { items: selectedItems },
  });

/* 탈퇴 회원 조회 */

export const getResignedUsers = ({
  page,
  size,
  name,
  email,
  nickName,
  snsType,
}) =>
  client.get("/user/", {
    params: {
      page: page,
      size: size,
      isResign: 1,
      name,
      email,
      nickName,
      snsType,
    },
  });
