import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  DatePicker,
  Radio,
  Image,
  Table,
} from "antd";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {saveBannerImage} from "../../../apis/shopping";
import * as userAPI from "../../../apis/user";
import Password from "antd/es/input/Password";

export default function PartnerUserInfoForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [item, setItem] = useState()
  const [loading, setLoading] = useState(true);

  const [image, setImage] = useState();

  const fetchItem = () => {
    userAPI
      .getUser({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setLoading(false);
          setItem(res.data.data)
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchItem();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      userAPI
        .editUser({
          ...item,
          ...values,
          is_staff: true,
          is_superuser: true,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (image) {
              saveBannerImage(res?.data?.data?.id, image, () => {
                message.success("성공적으로 수정되었습니다.");
                navigate(`/user/partner-user/info/${res.data.data.id}/`);
              })
            } else {
              message.success("성공적으로 수정되었습니다.");
              navigate(`/user/partner-user/info/${res.data.data.id}/`);
            }

          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      userAPI
        .createUser({
          ...values,
          is_staff: true,
          is_superuser: true,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (image) {
              saveBannerImage(res?.data?.data?.id, image, () => {
                message.success("성공적으로 저장 되었습니다.");
                navigate(`/user/partner-user/`);
              })
            } else {
              message.success("성공적으로 저장 되었습니다.");
              navigate(`/user/partner-user/`);
            }
          }else {
            message.error(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`파트너 유저 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={item}
              scrollToFirstError
              style={{ width: "50%" }}
            >

              <Form.Item
                label="어드민 / 파트너"
                name="isPartner"
                rules={[
                  {
                    required: true,
                    message: "어드민 / 파트너 를 선택해주세요.",
                  },
                ]}
              >
                <Radio.Group>
                  {["어드민", "파트너"].map((i) => (
                    <Radio value={i === '파트너'} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="name"
                label="이름"
                rules={[
                  {
                    required: true,
                    message: "이름을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="계정 ID (이메일 형식)"
                rules={[
                  {
                    required: true,
                    message: "게정 ID를 입력해주세요.",
                  },
                ]}
              >
                <Input placeholder={'이메일 형식으로 입력해 주세요.'}/>
              </Form.Item>

              <Form.Item
                name="password"
                label="계정 비밀번호"
                rules={[
                  {
                    required: true,
                    message: "비밀번호를 입력해주세요.",
                  },
                ]}
              >
                <Password />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/user/partner-user/`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
}
