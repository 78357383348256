import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from "../../components/Shopping/Category/InfoDetail";
import CategoryList from "../../components/Shopping/Category/CategoryList";

export default function CategoryPage() {
  return (
    <Routes>
      <Route path="/" element={<CategoryList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
