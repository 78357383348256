import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Segmented,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { Option } from "antd/lib/mentions";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";

export const List = () => {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [points, setPoints] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);
  const [resignReason, setResignReason] = useState(null);
  const [filter, setFilter] = useState("적립");
  const [config, setConfig] = useState([]);

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [form5] = Form.useForm();
  const [form6] = Form.useForm();
  const [form7] = Form.useForm();
  const [form8] = Form.useForm();
  const [form9] = Form.useForm();
  const [form10] = Form.useForm();
  const [form11] = Form.useForm();

  // const searchQuery = Form.useWatch('searchQuery', form);
  // const searchKeyword = Form.useWatch('searchKeyword', form);

  const fetchPoints = () => {
    const request = {
      page: current,
      size: 10,
    };

    systemAPI.getPoints(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setPoints(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
              regTime: dateToString(r.regTime),
              last_login: dateToString(r.last_login),
            };
          })
        );
        setLoading(false);
      }else {
        alert(res.data.data.msg);
      }
    });
  };

  const fetchConfings = () => {
    systemAPI.getConfigs().then((res) => {
      if (res.data.linusCode === 1) {
        setConfig(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
            };
          })
        );
        setLoading(false);
      }else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchPoints();
  }, [current, filter]);

  useEffect(() => {
    fetchConfings();
  }, [current, filter]);

  // const onSearch = (e) => {
  //   if (!searchQuery) {
  //     message.error('검색조건을 선택해주세요.');
  //     return;
  //   }

  //   if (!searchKeyword) {
  //     message.error('검색 키워드를 입력해주세요.');
  //     return;
  //   }

  //   const newSearchItem = {
  //     ...searchItem,
  //     searchQuery: searchQuery,
  //     searchKeyword: searchKeyword,
  //   };
  //   setSearchItem(newSearchItem);

  //   setCurrent(1);
  //   localStorage.setItem('page', 1);

  //   setLoading(true);
  //   fetchPoints();
  // };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const columns = [
    {
      title: "회원번호",
      dataIndex: "user",
      width: "8%",
      render: (val) => {
        return val?.id;
      },
    },
    {
      title: "이름",
      dataIndex: "user",
      render: (val) => {
        return val?.name ?? "-";
      },
    },
    {
      title: "닉네임",
      dataIndex: "user",
      render: (val) => {
        return val?.nickName ?? "-";
      },
    },
    {
      title: "이메일",
      dataIndex: "user",
      render: (val) => {
        return val?.email;
      },
    },
    {
      title: "내용",
      dataIndex: "desc",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "포인트",
      dataIndex: "point",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  const searchQueries = [
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "본문",
      dataIndex: "content",
    },
    {
      title: "지역",
      dataIndex: "address",
    },
  ];

  const onFinish = (values) => {
    console.log(values);
    systemAPI
      .editConfigs(values)
      .then((res) => {
        if (res.data?.linusCode === 1) {
          message.success("성공적으로 반영되었습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const PointForm = ({ name, label, form }) => {
    return (
      <Form
        form={form}
        name={name}
        onFinish={onFinish}
        initialValues={config.filter((i) => i.name === name)[0]}
        scrollToFirstError
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ opacity: 0, height: 0 }}>
          <Form.Item name="id" label="">
            <Input />
          </Form.Item>
        </div>
        <div style={{ display: "flex", width: "320px" }}>
          <Form.Item name="value" label={label}>
            <Input type="number" />
          </Form.Item>
          <Button htmlType="submit" type="primary">
            확인
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div>
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["적립", "차감"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      {!loading && filter === "적립" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PointForm label={"회원가입"} name={"userJoinPoint"} form={form1} />
          <PointForm label={"출석"} name={"userAttendancePoint"} form={form2} />
          <PointForm
            label={"플레이스토어 별점 및 후기 작성"}
            name={"playStoreReviewPoint"}
            form={form3}
          />
          <PointForm
            label={"페스티벌 링크 공유"}
            name={"festivalLinkSherePoint"}
            form={form4}
          />
          <PointForm label={"좋아요 선택"} name={"likePoint"} form={form5} />
          <PointForm
            label={"모임 공유"}
            name={"playGroupSherePoint"}
            form={form6}
          />
        </div>
      )}
      {!loading && filter === "차감" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PointForm
            label={"모임 개설"}
            name={"createPlayGroupPoint"}
            form={form9}
          />
        </div>
      )}
      <Divider />
      {!loading && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PointForm
            label={"도네이션 최소 포인트"}
            name={"donationMinimumPoint"}
            form={form7}
          />
          <PointForm
            label={"도네이션 선택 단위"}
            name={"donationSectionPoint"}
            form={form8}
          />
        </div>
      )}
      <PageHeader
        className="site-page-header"
        title="포인트 목록"
        subTitle={`총 ${total}개의 포인트 내역이 존재합니다.`}
      />
      {/* <Form form={form} onFinish={onSearch}>
        <FlexRowContainer style={{ marginTop: '30px' }}>
          <FlexRowQuarterContainer>
            <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>
              <Select placeholder="검색조건">
                {searchQueries?.map((c) => {
                  return (
                    <Select.Option key={c.dataIndex} value={c.dataIndex}>
                      {c.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchKeyword"
              initialValue={searchItem.searchKeyword}
            >
              <Input placeholder="검색 키워드를 입력하세요" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              />
            </Form.Item>
          </FlexRowQuarterContainer>
        </FlexRowContainer>
      </Form> */}
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={points}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/user/info/${record.user?.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
};
