import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from "../../components/Shopping/Ticket/InfoDetail";
import TicketList from "../../components/Shopping/Ticket/TicketList";

export default function TicketPage() {
  return (
    <Routes>
      <Route path="/" element={<TicketList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
