import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Select,
  Input,
  message,
  Upload,
} from "antd";

import * as communityAPI from "../../../apis/community";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  getFileNameFromURL,
  getImageListForUploadFromFlatArr,
} from "../../../utils/common";
import { DebounceSelect } from "../../Common/DebounceSelect";
import { TagInput } from "../../Common/TagInput";

export default function QnaForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [qna, setQna] = useState(null);
  const [tags, setTags] = useState([]);

  const [initialValue, setInitialValue] = useState(null);

  const [images, setImages] = useState([]);
  const [value, setValue] = useState([]);

  const fetchQna = () => {
    communityAPI
      .getPost({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setQna({
            ...res.data.data,
          });
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.response?.data.detail);
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchQna();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      communityAPI
        .editPost({
          ...qna,
          ...values,
          id: params.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 수정되었습니다.");
            navigate(`/community/faq/${res.data.data.id}`);
          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      communityAPI
        .createPost({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 등록되었습니다.");
            navigate(`/community/faq/${res.data.data.id}`);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [form, initialValue]);

  const onRemove = (image) => {
    if (isEdit) {
      fileAPI
        .deletePostImage(params.id, [image.id])
        .then((res) => {
          if (res.data.linusCode === 1) {
            setImages(getImageListForUploadFromFlatArr(res.data.result));
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
      return false;
    }
    return true;
  };

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`FAQ ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={qna}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item name="title" label="질문">
                <Input placeholder="질문을 입력해주세요." />
              </Form.Item>

              <Form.Item name="content" label="답변">
                <TextArea rows={10} placeholder="답변 내용을 입력해주세요." />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/community/faq`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
}
