import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Image,
  Pagination,
} from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as userAPI from "../../../apis/user";
import * as systemAPI from "../../../apis/system";

import { dateToString } from "../../../utils/common";

/*
회원번호, 닉네임, 회원등급, 가입이메일, 가입채널, 가입년월일시, 최종로그인년월일시,
마케팅동의여부, 마케팅동의년월일시, 생년월일, 연령대, 가입경로, 섭취영양제수,
섭취목적, 복용조합점수, 복용조합영양제수, 복용조합영양소수, 복용리스트(섭취영양제종류),
최근커뮤니티작성내역(표 형태,
    최대 10개 노출 : 커뮤니티종류(리뷰, 꿀팁, 꿀조합), 작성일, 최종수정일, 제목),
최근찜목록(표 형태, 최대 10개 노출)
*/
export default function InfoDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchUsers = () => {
    userAPI.getUser({ id: params.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setUser(res.data.data);

        const request = {
          page: current,
          size: 10,
          userEmail: res.data.data.id,
        };

        systemAPI.getPoints(request).then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setPoints(
              res.data.data?.map((r) => {
                return {
                  ...r,
                  key: r.id,
                };
              })
            );
            setLoading(false);
          }
        });
      }else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [current]);

  const pointColumns = [
    {
      title: "내용",
      dataIndex: "desc",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "포인트",
      dataIndex: "point",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="회원정보 조회" />
      <FlexRowReverseContainer>
        {/* <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/info/edit/${params.id}`);
          }}
        >
          수정
        </Button> */}
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/user/info`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && <Skeleton active />}
      {!loading && (
        <>
          <Descriptions labelStyle={{ fontWeight: 800 }}>
            <Descriptions.Item label="회원 번호">{params.id}</Descriptions.Item>
            <Descriptions.Item label="닉네임">
              {user.nickName ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="이름">
              {user.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="이메일">
              {user.email ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="성별">
              {user.gender ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="본인인증 여부">
              {user.isPhoneAuth ? "예" : '아니오'}
            </Descriptions.Item>
            <Descriptions.Item label="성인인증 여부">
              {user.isAdult ? "예" : '아니오'}
            </Descriptions.Item>
            <Descriptions.Item label="생년월일">
              {user.birthYear ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="가입 경로">
              {user.registrationPath ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="연락처">
              {user.phone ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="거주지역">
              {user.region ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="가입 일시">
              {dateToString(user.date_joined) ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="최종 로그인 일시" span={2}>
              {dateToString(user.last_login) ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="관심 지역">
              {user.interestRegion ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="관심 페스티벌">
              {user.interestFestival ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="좋아하는 음악 장르">
              {user.interestMusic ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="포인트">
              {user.point ?? "-"}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="vertical"
            labelStyle={{ fontWeight: 800, marginTop: "20px" }}
          >
            <Descriptions.Item label="포인트 내역">
              <Table
                pagination={false}
                columns={pointColumns}
                dataSource={points}
                size="small"
                width="100%"
                style={{ width: "100%" }}
                bordered
              />
            </Descriptions.Item>
          </Descriptions>
          <FlexRowEndContainer style={{ marginTop: "30px" }}>
            <Pagination
              activePage={current}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={onChange}
            />
          </FlexRowEndContainer>
        </>
      )}
    </div>
  );
}
