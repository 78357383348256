import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  DatePicker,
  Radio,
  Image,
  Table,
} from "antd";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import * as shoppingAPI from "../../../apis/shopping";
import {saveBannerImage} from "../../../apis/shopping";

export default function BannerForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [item, setItem] = useState()
  const [loading, setLoading] = useState(true);

  const [image, setImage] = useState();

  const fetchItem = () => {
    shoppingAPI
      .getBanner({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setLoading(false);
          setItem(res.data.data)
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchItem();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      shoppingAPI
        .editBanner({
          ...item,
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (image) {
              saveBannerImage(res?.data?.data?.id, image, () => {
                message.success("성공적으로 수정되었습니다.");
                navigate(`/shopping/banner/info/${res.data.data.id}/`);
              })
            } else {
              message.success("성공적으로 수정되었습니다.");
              navigate(`/shopping/banner/info/${res.data.data.id}/`);
            }

          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      shoppingAPI
        .createBanner({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (image) {
              saveBannerImage(res?.data?.data?.id, image, () => {
                message.success("성공적으로 저장 되었습니다.");
                navigate(`/shopping/banner/info/${res.data.data.id}/`);
              })
            } else {
              message.success("성공적으로 저장 되었습니다.");
              navigate(`/shopping/banner/info/${res.data.data.id}/`);
            }
          }else {
              message.error(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const onUpload = (img) => {
    console.log(img)
    setImage(img)
    // systemAPI.saveEventImage(img).then((res) => {
    //   if (res.data.linusCode === 1) {
    //     setImage([...image, res.data.data.image]);
    //     setImageId([...imageId, res.data.data.id]);
    //   } else {
    //     alert(res.data.data.msg);
    //   }
    // });

    return false;
  };

  const columns = [
    {
      title: "순서",
      dataIndex: "index",
      width: "10%",
    },
    {
      title: "이미지",
      dataIndex: "images",
      render: (val) => <Image src={val} />,
      width: "20%",
    },
    {
      title: "이벤트 제목",
      dataIndex: "title",
      width: "25%",
    },
    {
      title: "이벤트 내용",
      dataIndex: "content",
    },
    {
      title: "URL 이동",
      width: "15%",
      render: (val) => {
        return (
          <div>
            <a href={val.url} target="_blank" rel="noreferrer">
              이동
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`배너 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={item}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="name"
                label="배너 제목"
                rules={[
                  {
                    required: true,
                    message: "배너 제목을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="startDate" label="시작일시">
                <input type={"datetime-local"} />
              </Form.Item>

              <Form.Item name="endDate" label="종료일시">
                <input type={"datetime-local"} />
              </Form.Item>

              <Form.Item name="product" label="연결할 상품 ID">
                <Input type="number" placeholder={'상품 ID (숫자만)'} />
              </Form.Item>
              <button style={{marginBottom: 20}} onClick={() => window.open('/shopping/product/')}>
                상품 리스트 보기
              </button>

              <Form.Item name="event" label="연결할 ID">
                <Input type="number" placeholder={'이벤트 ID (숫자만)'} />
              </Form.Item>
              <button style={{marginBottom: 20}} onClick={() => window.open('/system/event/')}>
                이벤트 리스트 보기
              </button>

              <Form.Item label="노출 순서" name="index">
                <Input type="number" />
              </Form.Item>

              <Form.Item name="url" label="외부 링크">
                <Input />
              </Form.Item>

              <Form.Item label="노출 여부" name="isShow">
                <Radio.Group>
                  {["비노출", "노출"].map((i) => (
                    <Radio value={i === '노출'} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="이미지 업로드">
                <Upload
                  beforeUpload={onUpload}
                  showUploadList={false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>이미지 업로드 (기존 이미지가 대체 됩니다)</Button>
                </Upload>
              </Form.Item>

              <Image
                width={300}
                key={1}
                src={image?.uid?.length ? URL.createObjectURL(image) : item?.image ? item?.image : item?.url}
                alt="배너 사진"
                style={{ maxWidth: "400px" }}
              />


              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/shopping/banner/`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
}
