import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReportList from '../../components/Community/Report/ReportList';

export default function ReportPage() {
  return (
    <Routes>
      <Route path="/*" element={<ReportList />} />
    </Routes>
  );
}
