import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Image,
  Pagination,
} from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as shoppingAPI from "../../../apis/shopping";
import * as systemAPI from "../../../apis/system";

import {dateToString, getDiscountRate} from "../../../utils/common";
import {getProduct} from "../../../apis/shopping";

/*
회원번호, 닉네임, 회원등급, 가입이메일, 가입채널, 가입년월일시, 최종로그인년월일시,
마케팅동의여부, 마케팅동의년월일시, 생년월일, 연령대, 가입경로, 섭취영양제수,
섭취목적, 복용조합점수, 복용조합영양제수, 복용조합영양소수, 복용리스트(섭취영양제종류),
최근커뮤니티작성내역(표 형태,
    최대 10개 노출 : 커뮤니티종류(리뷰, 꿀팁, 꿀조합), 작성일, 최종수정일, 제목),
최근찜목록(표 형태, 최대 10개 노출)
*/
export default function InfoDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );

  const [inquiries, setInquiries] = useState([])

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const getInquiries = (productId) => {
    shoppingAPI.getInquiries({
      page: 1,
      size: 100,
      product: productId
    }).then(res => {
      if (res.data.linusCode === 1) {
        console.log(res.data.data)
        setInquiries(res.data.data)
      }
    })
  }

  const fetchProduct = () => {
    shoppingAPI.getProduct({ id: params.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setProduct(res.data.data);
        getInquiries(res?.data?.data?.id)

        const request = {
          page: current,
          size: 10,
          userEmail: res.data.data.id,
        };

        systemAPI.getPoints(request).then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setPoints(
              res.data.data?.map((r) => {
                return {
                  ...r,
                  key: r.id,
                };
              })
            );
            setLoading(false);
          }
        });
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchProduct();
  }, [current]);

  const pointColumns = [
    {
      title: "내용",
      dataIndex: "desc",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "포인트",
      dataIndex: "point",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="상품정보 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/product/edit/${params.id}`);
          }}
        >
          수정
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/product/`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && <Skeleton active />}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="상품 ID">{params.id}</Descriptions.Item>
            <Descriptions.Item label="상품명">
              {product.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="내용">
              {product.content ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="배송/무배송 유형">
              {product?.isDelivery ? '배송' : '무배송'}
            </Descriptions.Item>
            <Descriptions.Item label="원가">
              {product?.price ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="할인가">
              {product?.discountPrice ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="할인율">
              {getDiscountRate(product?.price, product?.discountPrice) + '%'}
            </Descriptions.Item>
            <Descriptions.Item label="배송비">
              {product.deliveryFee ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="배송 소요 기간">
              {product?.deliveryPeriod + '일 이내 배송'}
            </Descriptions.Item>
            <Descriptions.Item label="성인인증 여부">
              {product?.isAdult ? '성인 전용' : '전체 구매 가능'}
            </Descriptions.Item>
            <Descriptions.Item label="적립포인트">
              {product?.earnedPoint ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="상품 수량">
              {product.quantity ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="상품 태그">
              {product.tags?.map(item => item?.name).join(', ') ?? "-"}
            </Descriptions.Item>
          </Descriptions>
          <div>
            <div style={{fontSize: 18, marginTop: 40, marginBottom: 20}}>문의 내역</div>
            {inquiries?.map((item, index) => {
              return (
                <div style={{marginBottom: 15}}>
                  <Descriptions
                    labelStyle={{ fontWeight: 800 }}
                    bordered
                    column={6}
                    layout="vertical"
                  >
                    <Descriptions.Item label="상품 ID">{item.id}</Descriptions.Item>
                    <Descriptions.Item label="문의 상태">
                      {item.state ?? "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="내용">
                      {item.content ?? "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="답변">
                      {item.answer ?? "-"}
                    </Descriptions.Item>
                    {item.answer?.length ? null :
                      <Descriptions.Item label="답변 하러 가기">
                        <Button key="submit" type="primary" onClick={() => {
                          navigate(`/shopping/inquiry/info/${item?.id}/`)
                        }}>
                          답변 하러 가기
                        </Button>
                      </Descriptions.Item>
                    }
                  </Descriptions>
                </div>
              )
            })}
          </div>
        </>
      )}

    </div>
  );
}
