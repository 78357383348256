import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  Skeleton,
  Image,
  Pagination,
} from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as shoppingAPI from "../../../apis/shopping";
import * as systemAPI from "../../../apis/system";

import {dateToLongString, dateToString, getDiscountRate} from "../../../utils/common";
import {deleteBanner, getOrder, getProduct} from "../../../apis/shopping";

/*
회원번호, 닉네임, 회원등급, 가입이메일, 가입채널, 가입년월일시, 최종로그인년월일시,
마케팅동의여부, 마케팅동의년월일시, 생년월일, 연령대, 가입경로, 섭취영양제수,
섭취목적, 복용조합점수, 복용조합영양제수, 복용조합영양소수, 복용리스트(섭취영양제종류),
최근커뮤니티작성내역(표 형태,
    최대 10개 노출 : 커뮤니티종류(리뷰, 꿀팁, 꿀조합), 작성일, 최종수정일, 제목),
최근찜목록(표 형태, 최대 10개 노출)
*/
export default function InfoDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchItem = () => {
    shoppingAPI.getBanner({ id: params?.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setItem(res.data.data);

        const request = {
          page: current,
          size: 10,
          userEmail: res.data.data.id,
        };

        systemAPI.getPoints(request).then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setPoints(
              res.data.data?.map((r) => {
                return {
                  ...r,
                  key: r.id,
                };
              })
            );
            setLoading(false);
          }
        });
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchItem();
  }, [current]);

  const deleteBanner = () => {
    if (window.confirm('해당 배너를 삭제하시겠습니까?')) {
      shoppingAPI.deleteBanner({id: item?.id}).then(res => {
        navigate('/shopping/banner/')
      })
    }
  }


  return (
    <div>
      <PageHeader className="site-page-header" title="배너 정보 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            deleteBanner()
          }}
        >
          삭제
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/banner/edit/${params.id}/`);
          }}
        >
          수정
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/banner/`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && <Skeleton active />}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="배너 ID">{params?.id}</Descriptions.Item>
            <Descriptions.Item label="배너 제목">
              {item.name ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="노출 여부">
              {item?.isShow ? '예': '아니오'}
            </Descriptions.Item>
            <Descriptions.Item label="노출 시작 시간">
              {item.startDate ? dateToLongString(new Date(item.startDate)) : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="노출 종료 시간">
              {item.endDate ? dateToLongString(new Date(item.endDate)) : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="배너 노출 순서">
              {item?.index ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="배너 url">
              {item?.url ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="연결된 상품">
              {item?.product ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="연결된 이벤트">
              {item?.event ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="배너 이미지">
              <img src={item?.image} style={{width: 200, height: 200}}/>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
