import client from "./client";

/* 댓글 */

export const editComment = ({
  id,
  writerNickName,
  category,
  target,
  comment,
  isDeleted,
}) =>
  client.patch(`/community/comment/${id}/`, {
    writerNickName,
    category,
    target,
    comment,
    isDeleted,
  });

/* 신고접수 관리 */

export const getReports = ({ page, size, isPlayGroup, isComment }) =>
  client.get(`/report/`, {
    params: {
      page: page,
      size: size,
      isPlayGroup,
      isComment,
    },
  });

export const editReport = ({ id, isCompleted, isDeleted, isExecution }) =>
  client.put(`/report/${id}/`, {
    isCompleted,
    isDeleted,
    isExecution,
  });

export const deleteReports = ({ id }) => client.delete(`/report/${id}/`);

/* 고객 관리 */

export const getInquiries = ({ page, size }) =>
  client.get(`/inquiry/`, {
    params: {
      page: page,
      size: size,
    },
  });

export const getInquiry = ({ id }) => client.get(`/inquiry/${id}/`);

export const getFAQs = ({ page, size }) =>
  client.get(`/f-a-q/`, {
    params: {
      page: page,
      size: size,
    },
  });

export const getFAQ = ({ id }) => client.get(`/f-a-q/${id}/`);

/* 고객 관리 */

export const getPosts = ({
  page,
  size,
  category,
  title,
  writerNickName,
  writerEmail,
}) =>
  client.get(`/community/free/`, {
    params: {
      page: page,
      size: size,
      category,
      title,
      writerNickName,
      writerEmail,
    },
  });

export const getPost = ({ id }) => client.get(`/f-a-q/${id}/`);

export const createPost = ({ title, content }) =>
  client.post(`/f-a-q/`, {
    title,
    content,
  });

export const editPost = ({ id, title, content }) =>
  client.put(`/f-a-q/${id}/`, {
    title,
    content,
  });

export const deletePosts = ({ selectedItems }) =>
  client.delete(`/faq-multi-delete/`, { data: { ids: selectedItems } });
