import client from "./client";
import axios from "axios";
import { BASE_URL } from "./client";

/* 이미지 */

export const savePostImage = (image, id) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/community/free/${id}/image-save/`, formData);
};

export const deletePostImage = (id, images) => {
  let formData = new FormData();
  formData.append("images", images);
  return client.delete(`/community/free/${id}/image-save/`, formData);
};

/* 엑셀 파일 */

const downloadExcelFile = (url, items, fileName) => {
  const accessToken = localStorage.getItem("accessToken");

  return axios({
    method: "GET",
    url: `${BASE_URL}${url}`,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      items: items,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: response.headers["content-type"],
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link);
    link.click();
  });
};

export const uploadProductExcelFile = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return client.post(`/product/excelupload/`, formData);
};

export const downloadProductExcelFile = (items) => {
  return downloadExcelFile("/product/exceldown/", items, "제품 정보 목록");
};

export const downloadProductSampleExcelFile = () => {
  return downloadExcelFile("/product/excelsample/", [], "제품 정보 샘플 파일");
};

export const downloadEventExcelFile = (items) => {
  return downloadExcelFile("/event/?isExcel=1", items, "이벤트 목록");
};

export const downloadHistoryExcelFile = (number, name) => {
  return downloadExcelFile(`/used-history/?isExcel=${number}`, [], name);
};
