import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  message,
  Image,
} from "antd";
import { FlexRowReverseContainer } from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as etcAPI from "../../../apis/etc";
import { dateToString } from "../../../utils/common";

export default function NoticeDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [notice, setNotice] = useState(null);

  const fetchNotice = () => {
    etcAPI
      .getNotice({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setNotice(res.data.data);
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="공지사항 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/notice/edit/${params.id}`);
          }}
        >
          수정
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/notice`);
          }}
          style={{ margin: "0 10px" }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {/* 글번호, 공지제목, 작성자명, 등록년월일시, 최정수정년월일시, 글내용*/}
      {loading && <></>}
      {!loading && (
        <>
          <Descriptions labelStyle={{ fontWeight: 800 }} bordered>
            <Descriptions.Item label="글번호" span={1}>
              {notice.id ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="공지 제목" span={2}>
              {notice.title}
            </Descriptions.Item>
            <Descriptions.Item label="글내용" span={3}>
              <div style={{ maxWidth: "700px", lineBreak: "word" }}>
                {notice.content ?? "-"}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="등록일시" span={1}>
              {dateToString(notice.createdDate) ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="최종수정일시" span={1}>
              {dateToString(notice.updatedDate) ?? "-"}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
