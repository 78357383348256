import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Detail from '../../components/System/Festival/Detail';
import FestivalForm from '../../components/System/Festival/Form';

import { List } from '../../components/System/Festival/List';

export default function FestivalPage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path="/:id" element={<Detail />} />
      <Route path="/edit/:id" element={<FestivalForm isEdit={true} />} />
      <Route path="/new" element={<FestivalForm isEdit={false} />} />
    </Routes>
  );
}
