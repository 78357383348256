import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from "../../components/Shopping/FAQ/InfoDetail";
import FaqList from "../../components/Shopping/FAQ/FaqList";

export default function FaqFormatPage() {
  return (
    <Routes>
      <Route path="/" element={<FaqList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
