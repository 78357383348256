import axios from "axios";
import { useRecoilState } from "recoil";
import { authItemState } from "../states/atoms";

const client = axios.create();

// export const BASE_URL = 'http://139.150.69.235:8000/web_admin/'
export const BASE_URL = "https://api.onfriends.net/web_admin";

client.defaults.baseURL = BASE_URL;

// client.defaults.headers.common['accessToken'] =
//     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiZXhwIjoxNjQ5MDU3NzUwfQ.tFzAS5xL_zyzWHBE_aHAx5COcer_p4_ybLASF7QwpLk'

client.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem("accessToken")) {
      const accessToken = localStorage.getItem("accessToken") ?? null;
      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        };
      } else {
        config.headers = {
          Accept: "application/json",
        };
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// client.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // 요청 실패 시 특정 작업 수행
//     if (error.response.status === 403) {
//       alert("토큰이 만료되었습니다. 로그아웃 후 재 로그인 해 주세요.");
//       return;
//     }
//     return Promise.reject(error);
//   }
// );

/*
  글로벌 설정 예시:

  // API 주소를 다른 곳으로 사용함
  client.defaults.baseURL = 'https://external-api-server.com/'

  // 헤더 설정
  client.defaults.headers.common['Authorization'] = 'Bearer a1b2c3d4';

  // 인터셉터 설정
  axios.intercepter.response.use(\
    response => {
      // 요청 성공 시 특정 작업 수행
      return response;
    },
    error => {
      // 요청 실패 시 특정 작업 수행
      return Promise.reject(error);
    }
  })
*/

export default client;
