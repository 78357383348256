import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Detail from '../../components/System/PlayGroup/Detail';
import { List } from '../../components/System/PlayGroup/List';

export default function PlayGroupPage() {
  return (
    <Routes>
      <Route path="/*" element={<List />} />
      <Route path="/:id" element={<Detail />} />
    </Routes>
  );
}
