import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  DatePicker,
  Radio,
  Image,
  Modal,
  Descriptions,
  Table,
} from "antd";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export default function FestivalForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [festival, setFestival] = useState(null);

  const [image, setImage] = useState([]);
  const [imageId, setImageId] = useState([]);

  const [timeTable, setTimeTable] = useState(null);

  const [celebs, setCelebs] = useState([]);
  const [celebImage, setCelebImage] = useState(null);

  const [liveMap, setLiveMap] = useState({});

  const [booths, setBooths] = useState([]);
  const [boothImage, setBoothImage] = useState(null);
  const [isEditBooth, setIsEditBooth] = useState(false);

  const fetchFestival = () => {
    systemAPI
      .getFestival({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setFestival(res.data.data);
          setImage(res.data.data.images);
          setImageId(res.data.data.imageIds);
          setTimeTable(res.data.data.timeLine);
          setCelebs(res.data.data.celebrities);
          setLiveMap(res.data.data.liveMap);
          setBooths(res.data.data.booths);
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchFestival();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      systemAPI
        .editFestival({
          ...festival,
          ...values,
          images: imageId,
          celebrities: celebs.map((i) => i.id),
          booths: booths.map((i) => i.id),
          liveMap: liveMap?.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (timeTable && !(typeof timeTable === "string")) {
                systemAPI.saveTimeTableImage(res.data.data.id, timeTable);
                message.success("성공적으로 수정되었습니다.");
                navigate(`/system/festival/${res.data.data.id}`);
              } else {
                message.success("성공적으로 수정되었습니다.");
                navigate(`/system/festival/${res.data.data.id}`);
              }
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.data?.msg) {
            message.error(error.response.data.data.msg);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      systemAPI
        .createFestival({
          ...values,
          images: imageId,
          celebrities: celebs.map((i) => i.id),
          booths: booths.map((i) => i.id),
          liveMap: liveMap?.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (timeTable && !(typeof timeTable === "string")) {
                systemAPI.saveTimeTableImage(res.data.data.id, timeTable);
                message.success("성공적으로 등록되었습니다.");
                navigate(`/system/festival/${res.data.data.id}`);
              } else {
                message.success("성공적으로 등록되었습니다.");
                navigate(`/system/festival/${res.data.data.id}`);
              }
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error?.response?.data?.data?.msg) {
            message.error(error.response.data.data.msg);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const onUpload = (img) => {
    systemAPI.saveFestivalImage(img).then((res) => {
      if (res.data.linusCode === 1) {
        setImage([...image, res.data.data.image]);
        setImageId([...imageId, res.data.data.id]);
      }else {
        alert(res.data.data.msg);
      }
    });

    return false;
  };

  const onUploadTimeTable = (img) => {
    setTimeTable(img);

    return false;
  };

  const [celebForm] = Form.useForm();
  const [celeb, setCeleb] = useState({});
  const [showCelebModal, setShowCelebModal] = useState(false);
  const [isEditCeleb, setIsEditCeleb] = useState(false);

  const onShowCelebModal = () => {
    console.log(celeb);
    setShowCelebModal(true);
  };

  const onFinishCeleb = (values) => {
    if (!isEditCeleb) {
      systemAPI
        .createCeleb({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (celebImage) {
                systemAPI.saveCeleblImage(res.data.data.id, celebImage);
                setCelebImage(null);
              }
              message.success("라인업이 성공적으로 등록되었습니다.");
              setCelebs([...celebs, res.data.data]);
              setShowCelebModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      setIsEditCeleb(false);

      systemAPI
        .editCeleb({
          ...values,
          id: celeb.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (celebImage) {
                systemAPI.saveCeleblImage(res.data.data.id, celebImage);
                setCelebImage(null);
              }
              message.success("라인업이 성공적으로 수정되었습니다.");
              setCelebs([
                ...celebs.filter((i) => i.id !== celeb.id),
                res.data.data,
              ]);
              setShowCelebModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const [liveMapForm] = Form.useForm();
  const [liveMapImage, setLiveMapImage] = useState(null);
  const [showLiveMapModal, setShowLiveMapModal] = useState(false);

  const onShowLiveMapModal = (e) => {
    e.preventDefault();

    setShowLiveMapModal(true);
  };

  const onFinishLiveMap = (values) => {
    systemAPI
      .createLiveMap({
        ...values,
      })
      .then((res) => {
        if (res.data.linusCode === 1) {
          if (res.data.linusCode === 1) {
            if (liveMapImage) {
              systemAPI.saveLiveMapImage(res.data.data.id, liveMapImage);
              setLiveMapImage(null);
            }
            message.success("라이브맵이 성공적으로 등록되었습니다.");
            setLiveMap(res.data.data);
            setShowLiveMapModal(false);
          } else {
            message.error(res.data.msg);
          }
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  const [boothForm] = Form.useForm();
  const [booth, setBooth] = useState({});
  const [showBoothModal, setShowBoothModal] = useState(false);

  const onShowBoothModal = (e) => {
    e.preventDefault();

    setShowBoothModal(true);
  };

  const onFinishBooth = (values) => {
    if (!isEditBooth) {
      systemAPI
        .createBooth({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (boothImage) {
                systemAPI.saveBoothImage(res.data.data.id, boothImage);
                setBoothImage(null);
              }
              message.success("라인업이 성공적으로 등록되었습니다.");
              setBooths([...booths, res.data.data]);
              setShowBoothModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      systemAPI
        .editBooth({
          ...values,
          id: booth.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (boothImage) {
                systemAPI.saveBoothImage(res.data.data.id, boothImage);
                setBoothImage(null);
              }
              message.success("라인업이 성공적으로 등록되었습니다.");
              setBooths([
                ...booths.filter((i) => i.id !== booth.id),
                res.data.data,
              ]);
              setShowBoothModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const celeColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "이름",
      dataIndex: "title",
    },
    {
      title: "내용",
      dataIndex: "content",
    },
    {
      title: "동작",
      render: (val) => (
        <div style={{ display: "flex" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setCeleb(val);
              celebForm.setFieldValue("title", val.title);
              celebForm.setFieldValue("content", val.content);
              setIsEditCeleb(true);
              setShowCelebModal(true);
            }}
            style={{ marginRight: "10px" }}
          >
            수정
          </Button>
          <Button
            danger
            onClick={(e) => {
              e.preventDefault();
              setCelebs(celebs.filter((i) => i.id !== val.id));
            }}
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];

  const boothColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "부스명",
      dataIndex: "title",
    },
    {
      title: "위도",
      dataIndex: "lat",
    },
    {
      title: "경도",
      dataIndex: "lon",
    },
    // {
    //     title: '푸시 제목',
    //     dataIndex: 'pushTitle',
    //   },
    //   {
    //   title: '푸시 내용',
    //   dataIndex: 'pushContent',
    //   },
    //   {
    //   title: '푸시 예약일시',
    //   dataIndex: 'pushReservationTime',
    //   },
    {
      title: "동작",
      render: (val) => (
        <div style={{ display: "flex" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setBooth(val);
              boothForm.setFieldValue("pushTitle", val.pushTitle);
              boothForm.setFieldValue("pushContent", val.pushContent);
              boothForm.setFieldValue(
                "pushReservationTime",
                val.pushReservationTime
              );
              boothForm.setFieldValue("pushIsEventUser", val.pushIsEventUser);
              boothForm.setFieldValue("title", val.title);
              boothForm.setFieldValue("content", val.content);
              boothForm.setFieldValue("cautionContent", val.cautionContent);
              boothForm.setFieldValue("address", val.address);
              boothForm.setFieldValue("addressTitle", val.addressTitle);
              boothForm.setFieldValue("lat", val.lat);
              boothForm.setFieldValue("lon", val.lon);
              setIsEditBooth(true);
              setShowBoothModal(true);
            }}
            style={{ marginRight: "10px" }}
          >
            수정
          </Button>
          <Button
            danger
            onClick={(e) => {
              e.preventDefault();
              setBooths(booths.filter((i) => i.id !== val.id));
            }}
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`페스티벌 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={festival}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item label="이미지 업로드">
                <Upload
                  beforeUpload={onUpload}
                  showUploadList={false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>
                    페스티벌 이미지 업로드
                  </Button>
                </Upload>
              </Form.Item>

              {image?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {image?.map((i, idx) => (
                    <div
                      key={i}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Image
                        width={100}
                        key={idx}
                        src={i}
                        alt="페스티벌 사진"
                        style={{ maxWidth: "400px" }}
                      />
                      <Button
                        danger
                        onClick={() => {
                          let imageCopy = [...image];
                          let imageIdCopy = [...imageId];

                          imageCopy.splice(idx, 1);
                          let deleted = imageIdCopy[idx];
                          imageIdCopy.splice(idx, 1);

                          systemAPI.deleteFestivalImage(deleted).then((res) => {
                            if (res.data.linusCode === 1) {
                              setImage(imageCopy);
                              setImageId(imageIdCopy);
                            }
                          });
                        }}
                      >
                        삭제
                      </Button>
                    </div>
                  ))}
                </ul>
              )}
              <Form.Item
                name="title"
                label="페스티벌 제목"
                rules={[
                  {
                    required: true,
                    message: "페스티벌 제목을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="content" label="페스티벌 내용">
                <TextArea rows={10} placeholder="내용을 입력해주세요." />
              </Form.Item>

              <Form.Item name="startDateTime" label="시작일시">
                <input type={"datetime-local"} />
              </Form.Item>

              <Form.Item name="endDateTime" label="종료일시">
                <input type={"datetime-local"} />
              </Form.Item>

              <Form.Item label="타임테이블 이미지 업로드">
                <Upload
                  beforeUpload={onUploadTimeTable}
                  showUploadList={false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                </Upload>
              </Form.Item>

              {timeTable && typeof timeTable === "string" && (
                <Image
                  width={100}
                  src={timeTable}
                  alt="타임테이블 사진"
                  style={{ maxWidth: "400px" }}
                />
              )}

              {timeTable && !(typeof timeTable === "string") && (
                <Image
                  width={100}
                  src={URL.createObjectURL(timeTable)}
                  alt="타임테이블 사진"
                  style={{ maxWidth: "400px" }}
                />
              )}

              <Form.Item label="카테고리" name="festivalType">
                <Radio.Group>
                  {["Culture", "Music", "Food", "Concert", "기타"].map((i) => (
                    <Radio value={i} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="국가" name="country">
                <Radio.Group>
                  {["한국", "일본", "미국", "기타"].map((i) => (
                    <Radio value={i} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="음악" name="musicType">
                <Radio.Group>
                  {["POP", "ROCK", "EDM", "HIPHOP", "기타"].map((i) => (
                    <Radio value={i} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="주소" name="address">
                <Input />
              </Form.Item>

              <Form.Item label="장소 이름" name="addressTitle">
                <Input />
              </Form.Item>

              <Form.Item label="MD Pick" name="isMDPick">
                <Radio.Group>
                  {[true, false].map((i) => (
                    <Radio value={i} key={i}>
                      {i ? "O" : "X"}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item name="url" label="외부 링크">
                <Input />
              </Form.Item>

              <Form.Item name="lat" label="위도">
                <Input type="number" />
              </Form.Item>

              <Form.Item name="lon" label="경도">
                <Input type="number" />
              </Form.Item>

              <Form.Item label="라이브맵">
                <Button onClick={onShowLiveMapModal}>등록하기</Button>
              </Form.Item>

              <Form.Item label="라인업">
                <Button
                  onClick={() => {
                    setCeleb({});
                    celebForm.setFieldValue("title", null);
                    celebForm.setFieldValue("content", null);
                    onShowCelebModal();
                  }}
                >
                  추가하기
                </Button>
              </Form.Item>

              <Descriptions
                layout="vertical"
                labelStyle={{ fontWeight: 800, marginTop: "0px" }}
              >
                <Descriptions.Item label="등록된 라인업">
                  <Table
                    columns={celeColumns}
                    dataSource={celebs?.map((c) => {
                      return { ...c, key: c.id };
                    })}
                    pagination={false}
                    size="small"
                    width="100%"
                    style={{ width: "100%" }}
                    bordered
                  />
                </Descriptions.Item>
              </Descriptions>

              <Form.Item label="부스">
                <Button
                  onClick={(e) => {
                    setBooth({});
                    setIsEditBooth(false);
                    boothForm.setFieldValue("pushTitle", null);
                    boothForm.setFieldValue("pushContent", null);
                    boothForm.setFieldValue("pushReservationTime", null);
                    boothForm.setFieldValue("pushIsEventUser", null);
                    boothForm.setFieldValue("title", null);
                    boothForm.setFieldValue("content", null);
                    boothForm.setFieldValue("cautionContent", null);
                    boothForm.setFieldValue("address", null);
                    boothForm.setFieldValue("addressTitle", null);
                    boothForm.setFieldValue("lat", null);
                    boothForm.setFieldValue("lon", null);
                    onShowBoothModal(e);
                  }}
                >
                  추가하기
                </Button>
              </Form.Item>

              <Descriptions
                layout="vertical"
                labelStyle={{ fontWeight: 800, marginTop: "0px" }}
              >
                <Descriptions.Item label="등록된 부스">
                  <Table
                    columns={boothColumns}
                    dataSource={booths?.map((c) => {
                      return { ...c, key: c.id };
                    })}
                    pagination={false}
                    size="small"
                    width="100%"
                    style={{ width: "100%" }}
                    bordered
                  />
                </Descriptions.Item>
              </Descriptions>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/system/festival`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
            <Modal
              width={400}
              title={`라인업 ${isEditCeleb ? "수정" : "추가"}`}
              visible={showCelebModal}
              onOk={onFinishCeleb}
              onCancel={() => {
                setShowCelebModal(false);
              }}
              footer={[]}
            >
              <Form
                form={celebForm}
                name="register-celeb"
                onFinish={onFinishCeleb}
                initialValues={celeb}
                scrollToFirstError
              >
                <Form.Item label="라인업 이미지 업로드">
                  <Upload
                    beforeUpload={(img) => {
                      setCelebImage(img);
                    }}
                    showUploadList={false}
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                  </Upload>
                </Form.Item>

                {celebImage && !(typeof celebImage === "string") && (
                  <Image
                    width={100}
                    src={URL.createObjectURL(celebImage)}
                    alt="라인업 사진"
                    style={{ maxWidth: "400px", marginBottom: "20px" }}
                  />
                )}

                <Form.Item name="title" label="이름">
                  <Input />
                </Form.Item>

                <Form.Item name="content" label="내용">
                  <TextArea rows={5} placeholder="내용을 입력해주세요." />
                </Form.Item>

                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditCeleb(false);
                        setCeleb({});
                        setShowCelebModal(false);
                      }}
                      style={{ margin: "0 10px" }}
                    >
                      목록
                    </Button>
                    <div>
                      {!isEditCeleb && (
                        <Button type="primary" htmlType="submit">
                          저장
                        </Button>
                      )}
                      {isEditCeleb && (
                        <Button type="primary" htmlType="submit">
                          수정
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              width={400}
              title="라이브맵 등록"
              visible={showLiveMapModal}
              onOk={onFinishLiveMap}
              onCancel={() => {
                setShowLiveMapModal(false);
              }}
              footer={[]}
            >
              <Form
                form={liveMapForm}
                name="register-liveMap"
                onFinish={onFinishLiveMap}
                initialValues={liveMap}
                scrollToFirstError
              >
                <Form.Item label="라이브앱 이미지 업로드">
                  <Upload
                    beforeUpload={(img) => {
                      setLiveMapImage(img);
                    }}
                    showUploadList={false}
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                  </Upload>
                </Form.Item>

                {liveMapImage && !(typeof liveMapImage === "string") && (
                  <Image
                    width={100}
                    src={URL.createObjectURL(liveMapImage)}
                    alt="라이브맵 사진"
                    style={{ maxWidth: "400px", marginBottom: "20px" }}
                  />
                )}

                <Form.Item name="title" label="이름">
                  <Input />
                </Form.Item>

                <Form.Item name="northWestLat" label="좌측 상단 위도">
                  <Input type="number" />
                </Form.Item>
                <Form.Item name="northWestLon" label="좌측 상단 경도">
                  <Input type="number" />
                </Form.Item>

                <Form.Item name="northEastLat" label="우측 상단 위도">
                  <Input type="number" />
                </Form.Item>
                <Form.Item name="northEastLon" label="우측 상단 경도">
                  <Input type="number" />
                </Form.Item>

                <Form.Item name="southWestLat" label="좌측 하단 위도">
                  <Input type="number" />
                </Form.Item>
                <Form.Item name="southWestLon" label="좌측 하단 경도">
                  <Input type="number" />
                </Form.Item>

                <Form.Item name="southEastLat" label="우측 하단 위도">
                  <Input type="number" />
                </Form.Item>
                <Form.Item name="southEastLon" label="우측 하단 경도">
                  <Input type="number" />
                </Form.Item>

                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowLiveMapModal(false);
                      }}
                      style={{ margin: "0 10px" }}
                    >
                      목록
                    </Button>
                    <Button type="primary" htmlType="submit">
                      저장
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              width={400}
              title={isEditBooth ? "부스 수정" : "부스 추가"}
              visible={showBoothModal}
              onOk={onFinishBooth}
              onCancel={() => {
                setBooth({});
                setShowBoothModal(false);
              }}
              footer={[]}
            >
              <Form
                form={boothForm}
                name="register-booth"
                onFinish={onFinishBooth}
                initialValues={booth}
                scrollToFirstError
              >
                {/* <Form.Item label="부스 이미지 업로드">
                    <Upload beforeUpload={(img) => {
                        setBoothImage(img);
                    }} showUploadList={false} listType="picture">
                        <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                    </Upload>
                </Form.Item> */}

                {boothImage && !(typeof boothImage === "string") && (
                  <Image
                    width={100}
                    src={URL.createObjectURL(boothImage)}
                    alt="부스 사진"
                    style={{ maxWidth: "400px", marginBottom: "20px" }}
                  />
                )}

                <Form.Item name="title" label="부스 이름">
                  <Input />
                </Form.Item>

                <Form.Item name="content" label="부스 내용">
                  <TextArea rows={5} placeholder="내용을 입력해주세요." />
                </Form.Item>

                <Form.Item name="cautionContent" label="유의사항">
                  <TextArea rows={5} placeholder="내용을 입력해주세요." />
                </Form.Item>

                <Form.Item label="주소" name="address">
                  <Input />
                </Form.Item>

                <Form.Item label="장소 이름" name="addressTitle">
                  <Input />
                </Form.Item>

                <Form.Item name="lat" label="위도">
                  <Input type="number" />
                </Form.Item>

                <Form.Item name="lon" label="경도">
                  <Input type="number" />
                </Form.Item>

                <Divider />

                <h3>부스 푸시 설정</h3>

                <Form.Item label="발송 유형" name="pushIsEventUser">
                  <Radio.Group>
                    <Radio value={true} key="true">
                      이벤트 참여한 유저
                    </Radio>
                    <Radio value={false} key="false">
                      이벤트 참여하지 않은 유저
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="pushTitle" label="푸시 제목">
                  <Input placeholder="푸시 제목을 입력해주세요." />
                </Form.Item>

                <Form.Item label="예약 시간" name="pushReservationTime">
                  <input type={"datetime-local"} />
                </Form.Item>

                <Form.Item name="pushContent" label="푸시 내용">
                  <TextArea rows={5} placeholder="내용을 입력해주세요." />
                </Form.Item>

                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowBoothModal(false);
                      }}
                      style={{ margin: "0 10px" }}
                    >
                      목록
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {isEditBooth ? "수정" : "저장"}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
