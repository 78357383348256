import React, { useEffect, useState } from "react";
import { Table, Divider, PageHeader, Button, message, Modal } from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";

export default function List() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dropouts, setDropouts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchDropouts = () => {
    const request = {
      page: current,
      size: 10,
    };

    systemAPI.getDropouts(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setDropouts(
          res.data.data?.map((r) => {
            return { ...r, key: r.id };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchDropouts();
  }, [current]);

  // 글번호, 강제 퇴장 기록제목, 작성자명, 등록년월일시, 최정수정년월일시
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "8%",
    },
    {
      title: "유저 아이디",
      dataIndex: "user",
      width: "8%",
      render: (val) => {
        return val?.id;
      },
    },
    {
      title: "유저 이메일",
      dataIndex: "user",
      width: "16%",
      render: (val) => {
        return val?.email;
      },
    },
    {
      title: "모임 아이디",
      dataIndex: "playGroup",
      width: "8%",
      render: (val) => {
        return val?.id;
      },
    },
    {
      title: "모임명",
      dataIndex: "playGroup",
      render: (val) => {
        return val?.title;
      },
    },
    {
      title: "강퇴 유저 아이디",
      dataIndex: "targetUser",
      width: "10%",
      render: (val) => {
        return val?.id;
      },
    },
    {
      title: "강퇴 유저 이메일",
      dataIndex: "targetUser",
      width: "12%",
      render: (val) => {
        return val?.email;
      },
    },
    {
      title: "실행일시",
      dataIndex: "createdDate",
      render: (text) => dateToString(text),
      width: "18%",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onDelete = (e) => {};

  const onEdit = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    navigate(`/system/dropout/edit/${selectedItems[0].id}`);
  };

  const onCreate = (e) => {
    e.preventDefault();

    navigate(`/system/dropout/new`);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onShowDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalVisible(true);
  };

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <PageHeader
        className="site-page-header"
        title="강제 퇴장 기록"
        subTitle={`총 ${total}개의 강제 퇴장 기록이 존재합니다.`}
      />
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={dropouts}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
}
