import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  Segmented,
  message,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as communityAPI from "../../../apis/community";
import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";

export default function ReportList() {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const [form] = Form.useForm();
  const searchQuery = Form.useWatch("searchQuery", form);
  const searchKeyword = Form.useWatch("searchKeyword", form);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState("모임");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchReports = () => {
    const request = {
      page: current,
      size: 10,
      isPlayGroup: filter === "모임" ? 1 : null,
      isComment: filter === "댓글" ? 1 : null,
    };

    communityAPI
      .getReports(request)
      .then((res) => {
        if (res.data.linusCode === 1) {
          setTotal(res.data.page.count);
          setReports(
            res.data.data?.map((r) => {
              return { ...r, key: r.id };
            })
          );
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    fetchReports();
  }, [current, filter]);

  const toggleIsCompleted = (newState, record) => {
    communityAPI
      .editReport({ id: record.id, isCompleted: newState })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchReports();
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  const toggleIsDeleted = (newState, record) => {
    communityAPI
      .editReport({ id: record.id, isExecution: newState })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchReports();
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  /* 
  커뮤니티명, 글번호, 제목, 신고접수년월일시, 신고처리여부, 활성화/비활성화(버튼 형태)
  */

  const columns = [
    {
      title: "글번호",
      dataIndex: "id",
      width: "7%",
    },
    {
      title: filter === "모임" ? "모임 제목" : "댓글 내용",
      dataIndex: filter === "모임" ? "playGroup" : "comment",
      width: "30%",
      render: (val) => {
        return filter === "모임" ? val?.title : val?.content;
      },
    },
    {
      title: "비고",
      dataIndex: "desc",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: filter === "모임" ? "유형" : "작성자 닉네임(id)",
      dataIndex: filter === "모임" ? "playGroup" : "user",
      render: (val) => {
        if (filter === "모임") {
          if (val?.festival) {
            return "페스티벌 모임";
          }
          return "일반 모임";
        }

        return `${val?.nickName} (${val?.id})`;
      },
      width: "8%",
    },
    {
      title: "신고접수일시",
      dataIndex: "createdDate",
      render: (date) => dateToString(date),
      width: "10%",
    },
    {
      title: "현재 활성여부",
      dataIndex: "isExecution",
      render: (isExecution) => {
        if (isExecution) {
          return "비활성";
        }
        return "활성";
      },
      width: "10%",
    },

    {
      title: "활성화 / 비활성화",
      dataIndex: "isExecution",
      render: (isExecution, record) => (
        <div>
          {isExecution && (
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleIsDeleted(false, record);
              }}
            >
              활성화
            </Button>
          )}
          {!isExecution && (
            <Button
              danger
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleIsDeleted(true, record);
              }}
            >
              비활성화
            </Button>
          )}
        </div>
      ),
      width: "12%",
    },
  ];

  const onFinish = (values) => {};

  const searchQueries = [
    {
      title: "신고자 이메일",
      dataIndex: "reporterEmail",
    },
    {
      title: "신고자 닉네임",
      dataIndex: "reporterNickName",
    },
  ];

  const onSearch = (e) => {
    if (!searchQuery) {
      message.error("검색조건을 선택해주세요.");
      return;
    }

    if (!searchKeyword) {
      message.error("검색 키워드를 입력해주세요.");
      return;
    }

    const newSearchItem = {
      ...searchItem,
      searchQuery: searchQuery,
      searchKeyword: searchKeyword,
    };
    setSearchItem(newSearchItem);

    setCurrent(1);
    localStorage.setItem("page", 1);

    setLoading(true);
    fetchReports();
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="신고 관리"
        subTitle={`총 ${total}개의 신고접수가 존재합니다.`}
      />
      <Divider />
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["모임", "댓글"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={reports}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (filter === "모임") {
                navigate(`/system/play-group/${record?.playGroup?.id}`);
              } else {
                navigate(
                  `/system/play-group/${record?.comment?.playGroup?.id}`
                );
              }
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
      {/* <Form form={form} onFinish={onSearch}>
        <FlexRowContainer style={{ marginTop: '30px' }}>
          <FlexRowQuarterContainer>
            <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>
              <Select placeholder="검색조건">
                {searchQueries?.map((c) => {
                  return (
                    <Select.Option key={c.dataIndex} value={c.dataIndex}>
                      {c.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchKeyword"
              initialValue={searchItem.searchKeyword}
            >
              <Input placeholder="검색 키워드를 입력하세요" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              />
            </Form.Item>
          </FlexRowQuarterContainer>
        </FlexRowContainer>
      </Form> */}
    </div>
  );
}
