import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Descriptions,
  message,
  Skeleton,
  Image,
} from "antd";
import { FlexRowReverseContainer } from "../../Common/Container.styles";

import * as communityAPI from "../../../apis/community";
import { dateToString } from "../../../utils/common";

export default function FAQDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [qna, setQna] = useState(null);

  const toggleCommentIsDeleted = (newState, record) => {
    communityAPI
      .editComment({ id: record.id, isDeleted: newState })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchQna();
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  // 댓글작성자, 내용, 작성일시, 활성화/비활성화(버튼 형태)
  const commentColumns = [
    {
      title: "내용",
      dataIndex: "comment",
    },
    {
      title: "댓글작성자",
      dataIndex: "writerNickName",
    },
    {
      title: "작성일시",
      dataIndex: "regTime",
      render: (date) => dateToString(date),
    },
    {
      title: "활성화 / 비활성화",
      dataIndex: "isDeleted",
      render: (isDeleted, record) => (
        <div>
          {isDeleted && (
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                toggleCommentIsDeleted(false, record);
              }}
            >
              활성화
            </Button>
          )}
          {!isDeleted && (
            <Button
              danger
              onClick={(e) => {
                e.preventDefault();
                toggleCommentIsDeleted(true, record);
              }}
            >
              비활성화
            </Button>
          )}
        </div>
      ),
    },
  ];

  const fetchQna = () => {
    communityAPI
      .getFAQ({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setQna(res.data.data);
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    fetchQna();
  }, []);

  const toggleIsDeleted = (newState, record) => {
    communityAPI
      .editPost({ id: record.id, isDeleted: newState })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchQna();
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="FAQ 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/community/customer`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && (
        <>
          <Skeleton active />
          <Skeleton.Image active />
        </>
      )}
      {/* 제품목록(표 형태 : 제품명, 브랜드, 직접등록여부), 
      댓글(표 형태 : 댓글작성자, 내용, 작성일시, 활성화/비활성화(버튼 형태)), 
      활성화/비활성화(버튼 형태) */}
      {!loading && (
        <>
          <Descriptions labelStyle={{ fontWeight: 800 }} bordered>
            <Descriptions.Item label="글번호" span={1}>
              {qna.id ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="질문" span={2}>
              {qna.title ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="내용" span={3}>
              {qna.content ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="등록일시" span={1}>
              {dateToString(qna.createdDate) ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="최종수정일시" span={2}>
              {dateToString(qna.updatedDate) ?? "-"}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
