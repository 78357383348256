import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NoticeList from '../../components/Etc/Notice/NoticeList';
import NoticeDetail from '../../components/Etc/Notice/NoticeDetail';
import NoticeForm from '../../components/Etc/Notice/NoticeForm';

export default function NoticePage() {
  return (
    <Routes>
      <Route path="/*" element={<NoticeList />} />
      <Route path="/:id" element={<NoticeDetail />} />
      <Route path="/edit/:id" element={<NoticeForm isEdit={true} />} />
      <Route path="/new" element={<NoticeForm isEdit={false} />} />
    </Routes>
  );
}
