import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  DatePicker,
  Radio,
  Image,
  Modal,
  Descriptions,
  Table,
} from "antd";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  deleteProductContentImage,
  deleteProductImage, getFaqFormats,
  saveProductContentImage,
  saveProductImage
} from "../../../apis/shopping";
import * as shoppingAPI from "../../../apis/shopping";

export default function ProductForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);

  const [image, setImage] = useState([]);
  const [imageId, setImageId] = useState([]);

  const [contentImage, setContentImage] = useState([]);
  const [contentImageId, setContentImageId] = useState([]);

  const [options, setOptions] = useState([]);
  const [optionImage, setOptionImage] = useState(null);

  const [faqs, setFaqs] = useState([]);
  const [faqFormats, setFaqFormats] = useState([]);

  const [categories, setCategories] = useState([])

  const fetchFaqFormats = () => {
    shoppingAPI.getFaqFormats({
      page: 1,
      size: 100
    }).then(res => {
      if (res.data.linusCode === 1) {
        setFaqFormats(res.data.data)
      }
    })
  }

  const fetchProduct = () => {
    shoppingAPI
      .getProduct({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setLoading(false);
          setItem(res.data.data);
          setImage(res.data.data.images);
          setImageId(res.data.data.images?.map(item => item.id));
          setContentImage(res.data.data.contentImages);
          setContentImageId(res.data.data.contentImages?.map(item => item.id));
          setOptions(res.data.data.productOptions);
          setFaqs(res.data.data.faqs)
          form.setFieldValue("tag", res.data.data.tags?.map(item => item.name)?.join(','));
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };


  const getCategories = () => {
    const request = {
      page: 1,
      size: 100,
      isDeleted: null,
    };

    shoppingAPI.getCategories(request).then((res) => {
      if (res.data.linusCode === 1) {
        setCategories(res.data.data)
      }
    })
  }

  useEffect(() => {
    getCategories()
    fetchFaqFormats()

    if (isEdit) {
      fetchProduct();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      shoppingAPI
        .editProduct({
          ...item,
          ...values,
          images: imageId,
          contentImages: contentImageId,
          productOptions: options.map((i) => i.id),
          faqs: faqs.map(item => item.id)
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 수정되었습니다.");
            navigate(`/shopping/product/info/${res.data.data.id}`);
          } else {
            message.error(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.data?.msg) {
            message.error(error.response.data.data.msg);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      shoppingAPI
        .createProduct({
          ...values,
          images: imageId,
          contentImages: contentImageId,
          productOptions: options.map((i) => i.id),
          faqs: faqs.map(item => item.id)
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 등록되었습니다.");
            navigate(`/shopping/product/info/${res.data.data.id}`);
          } else {
            message.error(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.data?.msg) {
            message.error(error.response.data.data.msg);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const onUpload = (img) => {
    shoppingAPI.saveProductImage(img).then((res) => {
      if (res.data.linusCode === 1) {
        setImage([...image, res.data.data.image]);
        setImageId([...imageId, res.data.data.id]);
      }else {
        alert(res.data.data.msg);
      }
    });

    return false;
  };

  const onContentImageUpload = (img) => {
    shoppingAPI.saveProductContentImage(img).then((res) => {
      if (res.data.linusCode === 1) {
        setContentImage([...contentImage, res.data.data.image]);
        setContentImageId([...contentImageId, res.data.data.id]);
      }else {
        alert(res.data.data.msg);
      }
    });

    return false;
  };

  const [optionForm] = Form.useForm();
  const [option, setOption] = useState({});
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [isEditOption, setIsEditOption] = useState(false);

  const onShowOptionModal = () => {
    console.log(option);
    setShowOptionModal(true);
  };

  const onFinishOption = (values) => {
    if (!isEditOption) {
      shoppingAPI
        .createOption({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (optionImage) {
                shoppingAPI.saveOptionImage(res.data.data.id, optionImage).then(imgRes => {
                  console.log('imgRes', imgRes)
                  setOptions([...options, imgRes.data])
                });
                setOptionImage(null);
              } else {
                setOptions([...options, res.data.data]);
              }
              message.success("옵션이 성공적으로 등록되었습니다.");
              setShowOptionModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      setIsEditOption(false);

      shoppingAPI
        .editOption({
          ...values,
          id: option.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              if (optionImage) {
                shoppingAPI.saveOptionImage(res.data.data.id, optionImage, () => setOptions([
                  ...options.filter((i) => i.id !== option.id),
                  res.data.data,
                ]));
                setOptionImage(null);
              } else {
                setOptions([
                  ...options.filter((i) => i.id !== option.id),
                  res.data.data,
                ]);
              }
              message.success("옵션이 성공적으로 수정되었습니다.");

              setShowOptionModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const optionColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "이미지",
      dataIndex: "images",
      render: val => {
        return (
          <img style={{width: 80}} src={val[0]?.url}/>
        )
      }
    },
    {
      title: "옵선명",
      dataIndex: "name",
    },
    {
      title: "가격",
      dataIndex: "price",
    },
    {
      title: "할인된 가격",
      dataIndex: "discountPrice",
    },
    {
      title: "티켓 시작 날짜",
      dataIndex: "ticketStartDate",
    },
    {
      title: "티켓 종료 날짜",
      dataIndex: "ticketEndDate",
    },
    {
      title: "동작",
      render: (val) => (
        <div style={{ display: "flex" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              console.log(val?.images[0])
              setOption(val);
              setOptionImage(val?.images?.length ? val?.images[0] : '')
              optionForm.setFieldValue("name", val.name);
              optionForm.setFieldValue("price", val.price);
              optionForm.setFieldValue("discountPrice", val.discountPrice);
              optionForm.setFieldValue("ticketStartDate", val.ticketStartDate);
              optionForm.setFieldValue("ticketStartDate", val.ticketStartDate);
              setIsEditOption(true);
              setShowOptionModal(true);
            }}
            style={{ marginRight: "10px" }}
          >
            수정
          </Button>
          <Button
            danger
            onClick={(e) => {
              e.preventDefault();
              setOptions(options.filter((i) => i.id !== val.id));
            }}
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];

  const [faqForm] = Form.useForm();
  const [faq, setFaq] = useState({});
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [isEditFaq, setIsEditFaq] = useState(false);

  const onFinishFaq = (values) => {
    if (!isEditFaq) {
      shoppingAPI
        .createFaq({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              setFaqs([...faqs, res.data.data]);
              message.success("FAQ가 성공적으로 등록되었습니다.");
              setShowFaqModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      setIsEditFaq(false);

      shoppingAPI
        .editFaq({
          ...values,
          id: faq.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              setFaqs([
                ...faqs.filter((i) => i.id !== faq.id),
                res.data.data,
              ]);
              message.success("FAQ가 성공적으로 수정되었습니다.");

              setShowFaqModal(false);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const faqColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "카테고리",
      dataIndex: "category",
    },
    {
      title: "FAQ 제목",
      dataIndex: "title",
    },
    {
      title: "내용",
      dataIndex: "contents",
    },
    {
      title: "동작",
      render: (val) => (
        <div style={{ display: "flex" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setFaq(val);
              faqForm.setFieldValue("category", val.category);
              faqForm.setFieldValue("title", val.title);
              faqForm.setFieldValue("contents", val.contents);
              setIsEditFaq(true);
              setShowFaqModal(true);
            }}
            style={{ marginRight: "10px" }}
          >
            수정
          </Button>
          <Button
            danger
            onClick={(e) => {
              e.preventDefault();
              setFaqs(faqs.filter((i) => i.id !== val.id));
            }}
          >
            삭제
          </Button>
        </div>
      ),
    },
  ];

  const faqFormatColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "카테고리",
      dataIndex: "category",
    },
    {
      title: "FAQ 제목",
      dataIndex: "title",
    },
    {
      title: "내용",
      dataIndex: "contents",
    },
    {
      title: "동작",
      render: (val) => (
        <div style={{ display: "flex" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              // setFaq(val);
              faqForm.setFieldValue("category", val.category);
              faqForm.setFieldValue("title", val.title);
              faqForm.setFieldValue("contents", val.contents);
              // setIsEditFaq(true);
              // setShowFaqModal(true);
            }}
            style={{ marginRight: "10px" }}
          >
            적용
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`상품 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={item}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item label="이미지 업로드">
                <Upload
                  beforeUpload={onUpload}
                  showUploadList={false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>
                    상품 이미지 업로드
                  </Button>
                </Upload>
              </Form.Item>

              {image?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {image?.map((i, idx) => (
                    <div
                      key={i}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Image
                        width={100}
                        key={idx}
                        src={i?.url ? i?.url : i}
                        alt="페스티벌 사진"
                        style={{ maxWidth: "400px" }}
                      />
                      <Button
                        danger
                        onClick={() => {
                          let imageCopy = [...image];
                          let imageIdCopy = [...imageId];

                          imageCopy.splice(idx, 1);
                          let deleted = imageIdCopy[idx];
                          imageIdCopy.splice(idx, 1);

                          shoppingAPI.deleteProductImage(deleted).then((res) => {
                            if (res.data.linusCode === 1) {
                              setImage(imageCopy);
                              setImageId(imageIdCopy);
                            }
                          });
                        }}
                      >
                        삭제
                      </Button>
                    </div>
                  ))}
                </ul>
              )}

              <Form.Item label="상세내용 이미지 업로드">
                <Upload
                  beforeUpload={onContentImageUpload}
                  showUploadList={false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>
                    상품 상세 이미지 업로드
                  </Button>
                </Upload>
              </Form.Item>

              {contentImage?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {contentImage?.map((i, idx) => (
                    <div
                      key={i}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Image
                        width={100}
                        key={idx}
                        src={i?.image ? i?.image : i}
                        alt="페스티벌 사진"
                        style={{ maxWidth: "400px" }}
                      />
                      <Button
                        danger
                        onClick={() => {
                          let imageCopy = [...contentImage];
                          let imageIdCopy = [...contentImageId];

                          imageCopy.splice(idx, 1);
                          let deleted = imageIdCopy[idx];
                          imageIdCopy.splice(idx, 1);

                          shoppingAPI.deleteProductContentImage(deleted).then((res) => {
                            if (res.data.linusCode === 1) {
                              setContentImage(imageCopy);
                              setContentImageId(imageIdCopy);
                            }
                          });
                        }}
                      >
                        삭제
                      </Button>
                    </div>
                  ))}
                </ul>
              )}

              <Form.Item label="카테고리" name="category">
                <Radio.Group>
                  {categories.map((i) => (
                    <Radio value={i.id} key={i}>
                      {i.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="name"
                label="상품 이름"
                rules={[
                  {
                    required: true,
                    message: "페스티벌 제목을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="content" label="상품 상세 내용">
                <TextArea rows={10} placeholder="내용을 입력해주세요." />
              </Form.Item>

              <Form.Item label="가격" name="price">
                <Input type={'number'}/>
              </Form.Item>

              <Form.Item label="할인된 가격" name="discountPrice">
                <Input type={'number'}/>
              </Form.Item>

              <Form.Item label="배송비" name="deliveryFee">
                <Input type={'number'}/>
              </Form.Item>

              <Form.Item label="배송 기간 (일)" name="deliveryPeriod">
                <Input type={'number'}/>
              </Form.Item>

              <Form.Item name="startDate" label="판매 시작 일시">
                <input type={"date"} />
              </Form.Item>

              <Form.Item name="endDate" label="판매 종료 일시">
                <input type={"date"} />
              </Form.Item>

              <Form.Item name="ticketStartDate" label="티켓 사용 시작 일시">
                <input type={"date"} />
              </Form.Item>

              <Form.Item name="ticketEndDate" label="티켓 사용 종료 일시">
                <input type={"date"} />
              </Form.Item>

              <Form.Item label="MD PICK 여부" name="isMDPick">
                <Radio.Group>
                  {['예', '아니오'].map((i) => (
                    <Radio value={i === '예'} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="성인인증 필요 여부" name="isAdult">
                <Radio.Group>
                  {['예', '아니오'].map((i) => (
                    <Radio value={i === '예'} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="최대 사용 가능 포인트" name="usedMaxPoint">
                <Input type={'number'}/>
              </Form.Item>

              <Form.Item label="적립 포인트" name="earnedPoint">
                <Input type={'number'}/>
              </Form.Item>

              <Form.Item label="배송 상품 유/무" name="isDelivery">
                <Radio.Group>
                  {['배송 상품', '무배송 상품'].map((i) => (
                    <Radio value={i === '배송 상품'} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="문의 전화 번호" name="phone">
                <Input />
              </Form.Item>

              <Form.Item label="수량" name="quantity">
                <Input type={'number'} />
              </Form.Item>

              <Form.Item label="태그" name="tag">
                <Input placeholder={', 콤마 구분으로 적어주세요.'} />
              </Form.Item>

              <Button key="submit" type="primary" onClick={() => {
                setIsEditOption(false)
                setOption({});
                setShowOptionModal(true)
              }}>옵션 추가</Button>

              <Descriptions
                layout="vertical"
                labelStyle={{ fontWeight: 800, marginTop: "20px" }}
              >
                <Descriptions.Item label="등록된 옵션 리스트">
                  <Table
                    columns={optionColumns}
                    dataSource={options?.map((c) => {
                      return { ...c, key: c.id };
                    })}
                    pagination={false}
                    size="small"
                    width="100%"
                    style={{ width: "100%" }}
                    bordered
                  />
                </Descriptions.Item>
              </Descriptions>

              <Button style={{marginTop: 20}} key="submit" type="primary" onClick={() => {
                setIsEditFaq(false)
                setFaq({});
                setShowFaqModal(true)
              }}>FAQ 추가</Button>

              <Descriptions
                layout="vertical"
                labelStyle={{ fontWeight: 800, marginTop: "20px" }}
              >
                <Descriptions.Item label="등록된 FAQ 리스트">
                  <Table
                    columns={faqColumns}
                    dataSource={faqs?.map((c) => {
                      return { ...c, key: c.id };
                    })}
                    pagination={false}
                    size="small"
                    width="100%"
                    style={{ width: "100%" }}
                    bordered
                  />
                </Descriptions.Item>
              </Descriptions>


              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/shopping/product/`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>

            </Form>
            <Modal
              width={400}
              title={`옵션 ${isEditOption ? "수정" : "추가"}`}
              visible={showOptionModal}
              onOk={onFinishOption}
              onCancel={() => {
                setShowOptionModal(false);
              }}
              footer={[]}
            >
              <Form
                form={optionForm}
                name="register-option"
                onFinish={onFinishOption}
                initialValues={option}
                scrollToFirstError
              >
                <Form.Item label="옵션 이미지 업로드">
                  <Upload
                    beforeUpload={(img) => {
                      setOptionImage(img);
                    }}
                    showUploadList={false}
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                  </Upload>
                </Form.Item>

                {optionImage && !(typeof optionImage === "string") && (
                  <Image
                    width={100}
                    src={URL.createObjectURL(optionImage)}
                    alt="라인업 사진"
                    style={{ maxWidth: "400px", marginBottom: "20px" }}
                  />
                )}

                <Form.Item name="name" label="옵션 명">
                  <Input />
                </Form.Item>

                <Form.Item name="price" label="가격">
                  <Input type={'number'}/>
                </Form.Item>

                <Form.Item name="discountPrice" label="할인된 가격">
                  <Input type={'number'}/>
                </Form.Item>

                <Form.Item name="ticketStartDate" label="티켓 사용 시작 일시">
                  <input type={"date"} />
                </Form.Item>

                <Form.Item name="ticketEndDate" label="티켓 사용 종료 일시">
                  <input type={"date"} />
                </Form.Item>

                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditOption(false);
                        setOption({});
                        setShowOptionModal(false);
                      }}
                      style={{ margin: "0 10px" }}
                    >
                      닫기
                    </Button>
                    <div>
                      {!isEditOption && (
                        <Button type="primary" htmlType="submit">
                          저장
                        </Button>
                      )}
                      {isEditOption && (
                        <Button type="primary" htmlType="submit">
                          수정
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              width={800}
              title={`옵션 ${isEditFaq ? "수정" : "추가"}`}
              visible={showFaqModal}
              onOk={onFinishFaq}
              onCancel={() => {
                setShowFaqModal(false);
              }}
              footer={[]}
            >
              <Form
                form={faqForm}
                name="register-faq"
                onFinish={onFinishFaq}
                initialValues={faq}
                scrollToFirstError
              >
                <Form.Item name="category" label="카테고리">
                  <Radio.Group>
                    {['주문/결제', '배송', '취소/반품/교환/AS', '환불', '기타'].map((i) => (
                      <Radio value={i} key={i}>
                        {i}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="title" label="FAQ 제목">
                  <Input />
                </Form.Item>

                <Form.Item name="contents" label="내용">
                  <TextArea />
                </Form.Item>

                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditFaq(false);
                        setFaq({});
                        setShowFaqModal(false);
                      }}
                      style={{ margin: "0 10px" }}
                    >
                      닫기
                    </Button>
                    <div>
                      {!isEditFaq && (
                        <Button type="primary" htmlType="submit">
                          저장
                        </Button>
                      )}
                      {isEditFaq && (
                        <Button type="primary" htmlType="submit">
                          수정
                        </Button>
                      )}
                    </div>
                  </div>
                </Form.Item>
              </Form>

              <Descriptions
                layout="vertical"
                labelStyle={{ fontWeight: 800, marginTop: "20px" }}
              >
                <Descriptions.Item label="등록된 FAQ 포맷 리스트">
                  <Table
                    columns={faqFormatColumns}
                    dataSource={faqFormats?.map((c) => {
                      return { ...c, key: c.id };
                    })}
                    pagination={false}
                    size="small"
                    width="100%"
                    style={{ width: "100%" }}
                    bordered
                  />
                </Descriptions.Item>
              </Descriptions>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
