import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Segmented,
  DatePicker,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as systemAPI from "../../../apis/system";
import { dateToCompactString, dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { Option } from "antd/lib/mentions";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";

export const List = () => {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [playGroups, setPlayGroups] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);
  const [resignReason, setResignReason] = useState(null);
  const [filter, setFilter] = useState("전체");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [form] = Form.useForm();
  const [dateForm] = Form.useForm();

  const searchQuery = Form.useWatch("searchQuery", form);
  const searchKeyword = Form.useWatch("searchKeyword", form);

  const [startCreatedDate, setStartCreatedDate] = useState(null);
  const [endCreatedDate, setEndCreatedDate] = useState(null);

  const fetchPlayGroups = () => {
    const request = {
      page: current,
      size: 10,
      isFestival: filter === "전체" ? null : filter === "페스티벌" ? 1 : 2,
      startCreatedDate,
      endCreatedDate,
    };
    request[`${searchQuery}`] = searchKeyword;
    request[`${searchItem.searchQuery}`] = searchItem.searchKeyword;

    systemAPI.getPlayGroups(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setPlayGroups(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
              regTime: dateToString(r.regTime),
              last_login: dateToString(r.last_login),
            };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchPlayGroups();
  }, [
    current,
    filter,
    startCreatedDate,
    endCreatedDate,
    searchQuery,
    searchKeyword,
  ]);

  const onSearch = (e) => {
    if (!searchQuery) {
      message.error("검색조건을 선택해주세요.");
      return;
    }

    if (!searchKeyword) {
      message.error("검색 키워드를 입력해주세요.");
      return;
    }

    const newSearchItem = {
      ...searchItem,
      searchQuery: searchQuery,
      searchKeyword: searchKeyword,
    };
    setSearchItem(newSearchItem);

    setCurrent(1);
    localStorage.setItem("page", 1);

    setLoading(true);
    fetchPlayGroups();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // 모임번호, 닉네임, 가입채널, 가입년월일시, 최종로그인년월일시, 마케팅동의여부
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "제목",
      dataIndex: "title",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "본문",
      dataIndex: "content",
      width: "20%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "지역",
      dataIndex: "address",
      width: "20%",
    },
    {
      title: "모임 종류",
      dataIndex: "festival",
      width: "8%",
      render: (val) => {
        return val ? "페스티벌" : "모임";
      },
    },
    {
      title: "멤버 수",
      dataIndex: "attendUsers",
      width: "7%",
      render: (val) => {
        return val?.length;
      },
    },
    {
      title: "활성화 상태",
      dataIndex: "deletedDate",
      width: "7%",
      render: (val) => {
        return val ? "비활성화" : "활성화";
      },
    },
    {
      title: "활성화 / 비활성화",
      dataIndex: "deletedDate",
      render: (val, record) => (
        <div>
          {val && (
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                systemAPI.deletePlayGroup(record.id).then((res) => {
                  if (res.data.linusCode === 1) {
                    fetchPlayGroups();
                  }
                });
              }}
            >
              활성화
            </Button>
          )}
          {!val && (
            <Button
              danger
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                systemAPI.deletePlayGroup(record.id).then((res) => {
                  if (res.data.linusCode === 1) {
                    fetchPlayGroups();
                  }
                });
              }}
            >
              비활성화
            </Button>
          )}
        </div>
      ),
      width: "12%",
    },
    {
      title: "등록일시",
      dataIndex: "createdDate",
      width: "10%",
      render: (val) => {
        return dateToString(val);
      },
    },
  ];

  const searchQueries = [
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "본문",
      dataIndex: "content",
    },
    {
      title: "지역",
      dataIndex: "address",
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="모임정보 목록"
        subTitle={`총 ${total}개의 모임이 존재합니다.`}
      />
      <FlexRowContainer style={{ marginTop: "30px" }}>
        <Button
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            const newSearchItem = {
              ...searchItem,
              searchQuery: null,
              searchKeyword: null,
            };
            setSearchItem(newSearchItem);

            setCurrent(1);
            localStorage.setItem("page", 1);
            setFilter("전체");
            setStartCreatedDate(null);
            setEndCreatedDate(null);
            form.setFieldValue("searchQuery", null);
            form.setFieldValue("searchKeyword", null);
          }}
        >
          검색 조건 초기화
        </Button>
      </FlexRowContainer>
      <Form form={form} onFinish={onSearch}>
        <FlexRowContainer style={{ marginTop: "30px" }}>
          <FlexRowQuarterContainer>
            <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>
              <Select placeholder="검색조건">
                {searchQueries?.map((c) => {
                  return (
                    <Select.Option key={c.dataIndex} value={c.dataIndex}>
                      {c.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchKeyword"
              initialValue={searchItem.searchKeyword}
              style={{ width: "300px" }}
            >
              <Input placeholder="검색 키워드를 입력하세요" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              />
            </Form.Item>
          </FlexRowQuarterContainer>
        </FlexRowContainer>
      </Form>
      <Form form={dateForm}>
        <FlexRowContainer>
          <Form.Item label="등록일시">
            <div style={{ display: "flex" }}>
              <DatePicker
                onChange={(moment) => {
                  setStartCreatedDate(dateToCompactString(moment?._d));
                }}
              />
              <div style={{ margin: "0 10px" }}>~</div>
              <DatePicker
                onChange={(moment) => {
                  setEndCreatedDate(dateToCompactString(moment?._d));
                }}
              />
            </div>
          </Form.Item>
        </FlexRowContainer>
      </Form>
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["전체", "페스티벌", "모임"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={playGroups}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/system/play-group/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
};
