import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Descriptions,
  message,
  Skeleton,
  Image,
} from "antd";
import { FlexRowReverseContainer } from "../../Common/Container.styles";
import { Spin } from "antd";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

export default function Detail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [playGroup, setPlayGroup] = useState(null);
  const [image, setImage] = useState([]);
  const [imageId, setImageId] = useState([]);

  const fetchPlayGroup = () => {
    systemAPI
      .getPlayGroup({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setPlayGroup(res.data.data);
          setImage(res.data.data.images);
          setImageId(res.data.data.imageIds);
          setLoading(false);
        }else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchPlayGroup();
  }, [params.id]);

  // 영양성분명, 중요도, 함량, 단위
  const memberColumns = [
    {
      title: "회원번호",
      dataIndex: "id",
    },
    {
      title: "닉네임",
      dataIndex: "nickName",
    },
    {
      title: "성별",
      dataIndex: "gender",
    },
    {
      title: "생년",
      dataIndex: "birthYear",
    },
    {
      title: "거주 지역",
      dataIndex: "address",
    },
    {
      title: "관심 지역",
      dataIndex: "interestRegion",
    },
    {
      title: "관심 페스티벌",
      dataIndex: "interestFestival",
    },
    {
      title: "좋아하는 음악장르",
      dataIndex: "interestMusic",
    },
    {
      title: "모임장 여부",
      render: (val) => {
        return val.id === playGroup?.user?.id && "O";
      },
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="모임 정보 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/play-group`);
          }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && (
        <>
          <Skeleton active />
          <Skeleton.Image active />
        </>
      )}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="제목" span={3}>
              {playGroup.title ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="소개글" span={3}>
              {playGroup.desc ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="본문" span={6}>
              {playGroup.content ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="지역" span={3}>
              {playGroup.address ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="모임 종류" span={3}>
              {playGroup.festival ? "페스티벌" : "모임"}
            </Descriptions.Item>
            <Descriptions.Item label="멤버 수" span={3}>
              {playGroup.attendUsers?.length} 명
            </Descriptions.Item>
            <Descriptions.Item label="등록 일시" span={3}>
              {dateToString(playGroup.createdDate)}
            </Descriptions.Item>
            <Descriptions.Item label="모임 사진" span={6}>
              {playGroup.images?.length === 0 && "이미지가 없습니다."}
              {playGroup.images?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {playGroup.images?.map((i, idx) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Image
                        width={100}
                        key={idx}
                        src={i}
                        alt="모임사진"
                        style={{ maxWidth: "400px" }}
                      />
                      <Button
                        danger
                        onClick={() => {
                          let imageCopy = [...image];
                          let imageIdCopy = [...imageId];

                          imageCopy.splice(idx, 1);
                          let deleted = imageIdCopy[idx];
                          imageIdCopy.splice(idx, 1);

                          systemAPI
                            .deletePlayGroupImage(deleted)
                            .then((res) => {
                              if (res.data.linusCode === 1) {
                                fetchPlayGroup();
                              }else {
                                alert(res.data.data.msg);
                              }
                            });
                        }}
                      >
                        삭제
                      </Button>
                    </div>
                  ))}
                </ul>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="vertical"
            labelStyle={{ fontWeight: 800, marginTop: "20px" }}
          >
            <Descriptions.Item label="멤버 정보">
              <Table
                columns={memberColumns}
                dataSource={playGroup.attendUsers?.map((c) => {
                  return { ...c, key: c.id };
                })}
                pagination={false}
                size="small"
                width="100%"
                style={{ width: "100%" }}
                bordered
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
