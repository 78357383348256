import React, { useEffect, useState } from "react";
import "./index.css";
import "antd/dist/antd.min.css";
import {
  Layout,
  Menu,
  Breadcrumb,
  Button,
  message,
  Modal,
  Form,
  Input,
} from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  SearchOutlined,
  ShoppingOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { menus } from "./constants/menus";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UserInfoPage from "./pages/User/UserInfoPage";
import ResignUserPage from "./pages/User/ResignUserPage";
import ReportPage from "./pages/Community/ReportPage";
import NoticePage from "./pages/Etc/NoticePage";
import EventPage from "./pages/Etc/EventPage";
import HomePage from "./pages/Main/HomePage";
import * as userAPI from "./apis/user";
import QnaPage from "./pages/Community/QnaPage";
import { useRecoilState } from "recoil";
import { authItemState, searchItemState } from "./states/atoms";
import FAQDetail from "./components/Community/Qna/FAQDetail";
import QnaForm from "./components/Community/Qna/QnaForm";
import PlayGroupPage from "./pages/Etc/PlayGroupPage";
import FestivalPage from "./pages/Etc/FestivalPage";
import PushAlarmPage from "./pages/Etc/PushAlarmPage";
import PointPage from "./pages/Etc/PointPage";
import List from "./components/System/DropOut/List";
import ProductPage from "./pages/Shopping/ProductPage";
import FaqList from "./components/Shopping/FAQ/FaqList";
import FaqFormatPage from "./pages/Shopping/FaqFormatPage";
import OrderPage from "./pages/Shopping/OrderPage";
import InquiryPage from "./pages/Shopping/InquiryPage";
import NoDeliveryOrderPage from "./pages/Shopping/NoDeliveryOrderPage";
import TicketPage from "./pages/Shopping/TicketPage";
import CategoryPage from "./pages/Shopping/CategoryPage";
import SearchPage from "./pages/Shopping/SearchPage";
import BannerPage from "./pages/Shopping/BannerPage";
import BannerForm from "./components/Shopping/Banner/BannerForm";
import CategoryForm from "./components/Shopping/Category/CategoryForm";
import FaqForm from "./components/Shopping/FAQ/FaqForm";
import ProductForm from "./components/Shopping/Product/ProductForm";
import PartnerUserInfoPage from "./pages/PartnerUser/PartnerUserInfoPage";
import PartnerUserInfoForm from "./components/User/PartnerUserInfo/PartnerUserInfoForm";
import PartnerPage from "./pages/PartnerUser/PartnerPage";
import PartnerForm from "./components/User/Partner/PartnerForm";

const { Header, Content, Sider } = Layout;

function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchItem, setSearchItem] = useRecoilState(searchItemState);
  const [authItem, setAuthItem] = useRecoilState(authItemState);

  const [selectedMenu, setSelectedMenu] = useState({ title: "메인", path: "" });
  const [selectedSubMenu, setSelectedSubMenu] = useState({
    title: "홈",
    path: "",
  });

  const [isAuthed, setIsAuthed] = useState(false);

  const onClickMenu = (mainMenu, subMenu, mainIndex, subIndex) => {
    setSelectedMenu(mainMenu);
    setSelectedSubMenu(subMenu);
    localStorage.setItem("page", 1);
    localStorage.setItem("query", JSON.stringify({}));
    setSearchItem({});
    navigate(`/${mainMenu.path}/${subMenu.path}`);
  };

  useEffect(() => {
    userAPI
      .authCheck()
      .then((res) => {
        if (res.status === 200) {
          if (res.data.linusCode === 1) {
            setIsAuthed(true);
            setAuthItem({ isAuthed: true });
            return;
          } else {
            setIsAuthed(false);
            setAuthItem({ isAuthed: false });
            setVisible(true);
          }
        }
      })
      .catch((error) => {
        message.error("로그인이 필요합니다.");
        setVisible(true);
      });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      let mainPath = location.pathname.split("/")[1];
      let subPath = location.pathname.split("/")[2];

      let menu = menus.filter((m) => m.mainMenu.path === mainPath)[0];
      let mainMenu = menu.mainMenu;
      let subMenu = menu.subMenus.filter((m) => m.path === subPath)[0];

      setSelectedMenu(mainMenu);
      setSelectedSubMenu(subMenu);
    }
  }, [location]);

  const siderItems = [
    UserOutlined,
    LaptopOutlined,
    CommentOutlined,
    ShoppingOutlined,
  ]?.map((icon, index) => {
    return {
      key: `${menus[index].mainMenu.title}`,
      icon: React.createElement(icon),
      label: `${menus[index].mainMenu.title}`,
      children: menus[index].subMenus?.map((subMenu, idx) => {
        return {
          key: `${subMenu.title}`,
          label: `${subMenu.title}`,
          onClick: () =>
            onClickMenu(menus[index].mainMenu, subMenu, index, idx),
        };
      }),
    };
  });

  const [form] = Form.useForm();
  const userId = Form.useWatch("userId", form);
  const password = Form.useWatch("password", form);

  const onClickLogin = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  const handleOk = () => {
    setConfirmLoading(true);
    localStorage.removeItem("accessToken");

    userAPI
      .login({ userId, password })
      .then((res) => {
        if (res.data.linusCode === 1) {
          message.success("성공적으로 로그인되었습니다.");
          localStorage.setItem("accessToken", res.data.data.tokens.accessToken);
          setAuthItem({ isAuthed: true });
          setIsAuthed(true);
          setVisible(false);
        } else {
          message.error(res.data.data.msg);
          setAuthItem({ isAuthed: false });
          setIsAuthed(false);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("accessToken");
    setIsAuthed(false);
    userAPI
      .authCheck()
      .then((res) => {
        if (res.status === 200) {
          if (res.data.linusCode === 1) {
            setIsAuthed(true);
            setAuthItem({ isAuthed: true });
            return;
          } else {
            setIsAuthed(false);
            setAuthItem({ isAuthed: false });
          }
        }
      })
      .catch((error) => {
        message.error("로그인이 필요합니다.");
        setVisible(true);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Layout>
      <Modal
        width={400}
        title={`로그인`}
        visible={visible || !authItem.isAuthed}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <Form form={form}>
          <Form.Item name="userId" label="아이디">
            <Input placeholder="아이디를 입력하세요" />
          </Form.Item>
          <Form.Item name="password" label="패스워드">
            <Input placeholder="패스워드를 입력하세요" type={"password"} />
          </Form.Item>
        </Form>
      </Modal>
      <Header
        className="header"
        style={{
          display: "flex",
        }}
      >
        <div
          className="logo"
          style={{
            color: "white",
            fontWeight: 800,
            fontSize: "1.5em",
            cursor: "pointer",
            display: "flex",
            width: "50%",
          }}
          onClick={() => {
            setSelectedMenu({
              title: "메인",
              path: "",
            });
            setSelectedSubMenu({
              title: "홈",
              path: "",
            });
            navigate("/");
          }}
        >
          온프렌즈 관리자
        </div>
        <div
          className="logo"
          style={{
            color: "white",
            fontWeight: 800,
            fontSize: "1.5em",
            cursor: "pointer",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "50%",
          }}
        >
          {!isAuthed && (
            <Button ghost onClick={onClickLogin}>
              로그인
            </Button>
          )}
          {isAuthed && (
            <Button ghost onClick={onClickLogout}>
              로그아웃
            </Button>
          )}
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
              backgroundColor: '#eee'
            }}
            items={siderItems}
          />
        </Sider>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <Breadcrumb
            style={{
              margin: "40px 0 0 0",
            }}
          >
            <Breadcrumb.Item>{selectedMenu?.title}</Breadcrumb.Item>
            <Breadcrumb.Item>{selectedSubMenu?.title}</Breadcrumb.Item>
          </Breadcrumb>

          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: "30px 30px 0px 30px",
              minHeight: 800,
              backgroundColor: "white",
            }}
          >
            <Routes>
              <Route path="/" element={isAuthed ? <HomePage /> : <></>} />
              {/* 회원 */}
              <Route path="/user/info/*" element={<UserInfoPage />} />
              <Route path="/user/withdrawal/*" element={<ResignUserPage />} />
              {/* 커뮤니티 */}
              <Route path="/community/report/*" element={<ReportPage />} />
              <Route path="/community/customer/*" element={<QnaPage />} />
              <Route
                path="/community/faq/new"
                element={<QnaForm isEdit={false} />}
              />
              <Route
                path="/community/faq/edit/:id"
                element={<QnaForm isEdit={true} />}
              />
              <Route path="/community/faq/:id" element={<FAQDetail />} />
              <Route path="/community/faq/*" element={<QnaPage />} />
              {/* 기타 */}
              <Route path="/system/notice/*" element={<NoticePage />} />
              <Route path="/system/play-group/*" element={<PlayGroupPage />} />
              <Route path="/system/festival/*" element={<FestivalPage />} />
              <Route path="/system/event/*" element={<EventPage />} />
              <Route path="/system/push-alarm/*" element={<PushAlarmPage />} />
              <Route path="/system/point/*" element={<PointPage />} />
              <Route path="/system/drop-out/*" element={<List />} />

              <Route path="/shopping/product/*" element={<ProductPage />} />
              <Route path="/shopping/faq/*" element={<FaqFormatPage />} />
              <Route path="/shopping/order/*" element={<OrderPage />} />
              <Route path="/shopping/no-delivery-order/*" element={<NoDeliveryOrderPage />} />
              <Route path="/shopping/inquiry/*" element={<InquiryPage />} />
              <Route path="/shopping/ticket/*" element={<TicketPage />} />
              <Route path="/shopping/category/*" element={<CategoryPage />} />
              <Route path="/shopping/search/*" element={<SearchPage />} />
              <Route path="/shopping/banner/*" element={<BannerPage />} />
              <Route
                path="/shopping/banner/new"
                element={<BannerForm isEdit={false} />}
              />
              <Route
                path="/shopping/banner/edit/:id"
                element={<BannerForm isEdit={true} />}
              />
              <Route
                path="/shopping/category/new"
                element={<CategoryForm isEdit={false} />}
              />
              <Route
                path="/shopping/category/edit/:id"
                element={<CategoryForm isEdit={true} />}
              />
              <Route
                path="/shopping/faq/new"
                element={<FaqForm isEdit={false} />}
              />
              <Route
                path="/shopping/faq/edit/:id"
                element={<FaqForm isEdit={true} />}
              />
              <Route
                path="/shopping/product/new"
                element={<ProductForm isEdit={false} />}
              />
              <Route
                path="/shopping/product/edit/:id"
                element={<ProductForm isEdit={true} />}
              />
              <Route path="/user/partner-user/*" element={<PartnerUserInfoPage />} />
              <Route
                path="/user/partner-user/new"
                element={<PartnerUserInfoForm isEdit={false} />}
              />
              <Route
                path="/user/partner-user/edit/:id"
                element={<PartnerUserInfoForm isEdit={true} />}
              />
              <Route path="/user/partner/*" element={<PartnerPage />} />
              {/*<Route*/}
              {/*  path="/user/partner/new"*/}
              {/*  element={<PartnerForm isEdit={false} />}*/}
              {/*/>*/}
              <Route
                path="/user/partner/edit/:id"
                element={<PartnerForm isEdit={true} />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Main;
