import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  message,
  Modal,
  Segmented,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";

export default function EventList() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pointType, setPointType] = useState("전체");
  const [type, setType] = useState("전체");

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const onChangeType = (newVal) => {
    setType(newVal);
    onChange(1);
  };

  const onChangePointType = (newVal) => {
    setPointType(newVal);
    onChange(1);
  };

  const fetchEvents = () => {
    const request = {
      page: current,
      size: 10,
      type: type === "전체" ? null : type,
      pointType: pointType === "전체" ? null : pointType,
    };

    systemAPI.getEvents(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setEvents(
          res.data.data?.map((r) => {
            return { ...r, key: r.id };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchEvents();
  }, [current, type, pointType]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "메인배너 순서",
      dataIndex: "index",
      width: "8%",
    },
    {
      title: "메인배너 노출",
      dataIndex: "isMain",
      width: "8%",
      render: (isMain, record) => (
        <div>
          {!isMain && (
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                systemAPI
                  .editEvent({ id: record.id, isMain: true })
                  .then((res) => {
                    if (res.data.linusCode === 1 && res.data?.linusCode === 1) {
                      fetchEvents();
                    } else {
                      alert(res.data.data.msg);
                    }
                  });
              }}
            >
              노출
            </Button>
          )}
          {isMain && (
            <Button
              danger
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                systemAPI
                  .editEvent({ id: record.id, isMain: false })
                  .then((res) => {
                    if (res.data.linusCode === 1 && res.data?.linusCode === 1) {
                      fetchEvents();
                    } else {
                      alert(res.data.data.msg);
                    }
                  });
              }}
            >
              비노출
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "온/오프라인",
      dataIndex: "type",
      width: "8%",
    },
    {
      title: "포인트",
      dataIndex: "pointType",
      width: "8%",
    },
    {
      title: "참여자수",
      dataIndex: "attendUsers",
      width: "8%",
      render: (val) => {
        return val?.length;
      },
    },
    {
      title: "등록일시",
      dataIndex: "createdDate",
      render: (text) => dateToString(text),
      width: "12%",
    },
    {
      title: "최종수정일시",
      dataIndex: "updatedDate",
      render: (text) => dateToString(text),
      width: "12%",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onDelete = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    systemAPI
      .deleteEvents({ selectedItems: selectedItems?.map((i) => i.id) })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchEvents();
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        } else {
          alert(res.data.data.msg);
        }
      });
  };

  const onEdit = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    navigate(`/system/event/edit/${selectedItems[0].id}`);
  };

  const onCreate = (e) => {
    e.preventDefault();

    navigate(`/system/event/new`);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onShowDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalVisible(true);
  };

  const downloadEventExcelFile = (e) => {
    e.preventDefault();

    // if (selectedItems.length === 0) {
    //   message.error('선택된 항목이 없습니다.');
    //   return;
    // }

    fileAPI
      .downloadEventExcelFile(selectedItems?.map((i) => i.id))
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <PageHeader
        className="site-page-header"
        title="이벤트 관리"
        subTitle={`총 ${total}개의 이벤트가 존재합니다.`}
      />
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["전체", "온라인", "오프라인"]}
          value={type}
          onChange={onChangeType}
        />
      </FlexRowContainer>
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["전체", "적립형", "차감형"]}
          value={pointType}
          onChange={onChangePointType}
        />
      </FlexRowContainer>
      <FlexRowReverseContainer>
        <Button danger onClick={onShowDeleteModal}>
          선택 삭제
        </Button>
        <Button style={{ margin: "0 10px" }} onClick={onEdit}>
          선택 수정
        </Button>
        <Button
          style={{ margin: "0 0 0 10px" }}
          type="primary"
          onClick={onCreate}
        >
          추가
        </Button>
        <Button onClick={downloadEventExcelFile}>엑셀 다운로드</Button>
      </FlexRowReverseContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={events}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.preventDefault();
              navigate(`/system/event/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
}
