import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Form,
  Select,
  Input,
  Descriptions,
  message,
  Image,
} from "antd";
import { FlexRowReverseContainer } from "../../Common/Container.styles";
import { Option } from "antd/lib/mentions";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

export default function EventDetail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);

  const fsystemhEvent = () => {
    systemAPI
      .getEvent({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setEvent(res.data.data);
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    fsystemhEvent();
  }, []);

  const applyUserColumns = [
    {
      title: "닉네임",
      dataIndex: "nickName",
      width: "10%",
    },
    {
      title: "이메일",
      dataIndex: "email",
      width: "20%",
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="이벤트 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/event/edit/${params.id}`);
          }}
        >
          수정
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/event`);
          }}
          style={{ margin: "0 10px" }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {/* 글번호, 공지제목, 작성자명, 등록년월일시, 최정수정년월일시, 글내용*/}
      {loading && <></>}
      {!loading && (
        <>
          <Descriptions labelStyle={{ fontWeight: 800 }} bordered>
            <Descriptions.Item label="글번호" span={1}>
              {event.id ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="공지 제목" span={2}>
              {event.title}
            </Descriptions.Item>
            <Descriptions.Item label="내용" span={3}>
              <div style={{ maxWidth: "700px", lineBreak: "word" }}>
                {event.content ?? "-"}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="유의사항" span={3}>
              <div style={{ maxWidth: "700px", lineBreak: "word" }}>
                {event.cautionContent ?? "-"}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="기간" span={3}>
              {dateToString(event.startDateTime)} ~{" "}
              {dateToString(event.endDateTime)}
            </Descriptions.Item>
            <Descriptions.Item label="첨부 이미지" span={3}>
              {event.images?.length === 0 && "이미지가 없습니다."}
              {event.images?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {event.images?.map((i) => (
                    <Image
                      width={100}
                      key={i}
                      src={i}
                      alt="모임사진"
                      style={{ maxWidth: "400px" }}
                    />
                  ))}
                </ul>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="온/오프라인" span={1}>
              {event.type}
            </Descriptions.Item>
            <Descriptions.Item label="차감형/적립형" span={1}>
              {event.pointType}
            </Descriptions.Item>
            <Descriptions.Item label="포인트" span={1}>
              {event.point}
            </Descriptions.Item>
            <Descriptions.Item label="외부링크" span={3}>
              {event.url}
            </Descriptions.Item>
            <Descriptions.Item label="메인 배너 순서" span={3}>
              {event.index}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="vertical"
            labelStyle={{ fontWeight: 800, marginTop: "20px" }}
          >
            <Descriptions.Item label="이벤트 신청자">
              <Table
                columns={applyUserColumns}
                dataSource={event.attendUsers?.map((c) => {
                  return { ...c, key: c.id };
                })}
                pagination={false}
                size="small"
                width="100%"
                style={{ width: "100%", cursor: "pointer" }}
                bordered
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (user) => {
                      navigate(`/user/info/${record.id}`);
                    },
                  };
                }}
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
