import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Descriptions,
  message,
  Skeleton,
  Image,
} from "antd";
import { FlexRowReverseContainer } from "../../Common/Container.styles";
import { Spin } from "antd";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

export default function Detail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [festival, setFestival] = useState(null);

  const fetchFestival = () => {
    systemAPI
      .getFestival({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setFestival(res.data.data);
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchFestival();
  }, [params.id]);

  const boothColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "부스명",
      dataIndex: "title",
    },
    {
      title: "위도",
      dataIndex: "lat",
    },
    {
      title: "경도",
      dataIndex: "lon",
    },
    // {
    //   title: '푸시 제목',
    //   dataIndex: 'pushTitle',
    // },
    // {
    // title: '푸시 내용',
    // dataIndex: 'pushContent',
    // },
    // {
    // title: '푸시 예약일시',
    // dataIndex: 'pushReservationTime',
    // },
  ];

  const pushColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "푸시 제목",
      dataIndex: "title",
    },
    {
      title: "푸시 내용",
      dataIndex: "content",
    },
    {
      title: "발송 종류",
      dataIndex: "category",
    },
    {
      title: "예약 일시",
      dataIndex: "reservationTime",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "발송 일시",
      dataIndex: "sentTime",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "발신 여부",
      dataIndex: "isSent",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
    {
      title: "좋아요한 멤버",
      dataIndex: "isFestivalLikeUser",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
    // {
    // title: '스크랩한 멤버',
    // dataIndex: 'isFestivalLikeUser',
    // render: (val) => {
    //     return val ? 'O' : 'X'
    //     },
    // },
    {
      title: "페스티벌 모임",
      dataIndex: "isFestivalPlayGroupUser",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="페스티벌 정보 조회" />
      <FlexRowReverseContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/festival/edit/${params.id}`);
          }}
        >
          수정
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/festival`);
          }}
          style={{ margin: "0 10px" }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && (
        <>
          <Skeleton active />
          <Skeleton.Image active />
        </>
      )}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="제목" span={3}>
              {festival.title ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="카테고리" span={3}>
              {festival.festivalType ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="소개글" span={6}>
              {festival.content ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="장소" span={3}>
              {festival.address ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="일정" span={3}>
              {dateToString(festival.startDateTime)} ~{" "}
              {dateToString(festival.endDateTime)}
            </Descriptions.Item>
            <Descriptions.Item label="타임테이블" span={6}>
              <Image
                width={100}
                src={festival.timeLine}
                alt="타임테이블"
                style={{ width: "300px" }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="라인업" span={6}>
              {festival.celebrities?.map((i) => i.title).join(", ")}
            </Descriptions.Item>
            <Descriptions.Item label="페스티벌 사진" span={6}>
              {festival.images?.length === 0 && "이미지가 없습니다."}
              {festival.images?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {festival.images?.map((i) => (
                    <Image
                      width={100}
                      key={i}
                      src={i}
                      alt="사진"
                      style={{ maxWidth: "400px" }}
                    />
                  ))}
                </ul>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="vertical"
            labelStyle={{ fontWeight: 800, marginTop: "20px" }}
          >
            <Descriptions.Item label="부스 정보">
              <Table
                columns={boothColumns}
                dataSource={festival.booths?.map((c) => {
                  return { ...c, key: c.id };
                })}
                pagination={false}
                size="small"
                width="100%"
                style={{ width: "100%" }}
                bordered
              />
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="vertical"
            labelStyle={{ fontWeight: 800, marginTop: "20px" }}
          >
            <Descriptions.Item label="푸시 정보">
              <Table
                columns={pushColumns}
                dataSource={festival.pushAlarms?.map((c) => {
                  return { ...c, key: c.id };
                })}
                pagination={false}
                size="small"
                width="100%"
                style={{ width: "100%" }}
                bordered
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
}
