import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  Switch,
} from "antd";

import * as etcAPI from "../../../apis/etc";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export default function NoticeForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [notice, setNotice] = useState(null);

  const [image, setImage] = useState(null);

  const fetchNotice = () => {
    etcAPI
      .getNotice({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setNotice(res.data.data);
          setLoading(false);
        }else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchNotice();
    } else {
      setLoading(false);
    }
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      etcAPI
        .editNotice({
          ...notice,
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            message.success("성공적으로 수정되었습니다.");
            navigate(`/system/notice/${res.data.data.id}`);
          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      etcAPI
        .createNotice({
          ...values,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              message.success("성공적으로 등록되었습니다.");
              navigate(`/system/notice/${res.data.data.id}`);
            } else {
              alert(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const onUpload = (image) => {
    setImage(image);
  };

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`공지사항 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={notice}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="title"
                label="공지 제목"
                rules={[
                  {
                    required: true,
                    message: "공지 제목을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="content" label="글 내용">
                <TextArea rows={20} placeholder="글 내용을 입력해주세요." />
              </Form.Item>

              <Form.Item
                label="메인 노출"
                name="isMain"
                valuePropName="isMain"
                initialValue={false}
              >
                <Switch />
              </Form.Item>

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/system/notice`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
}
