import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Segmented,
  DatePicker,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as userAPI from "../../../apis/user";
import { dateToCompactString, dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { Option } from "antd/lib/mentions";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";

export const InfoList = () => {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [date, setDate] = useState(null);
  const [endCreatedDate, setEndCreatedDate] = useState(null);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);
  const [resignReason, setResignReason] = useState(null);
  const [filter, setFilter] = useState("전체");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [form] = Form.useForm();
  const [dateForm] = Form.useForm();
  let searchQuery = Form.useWatch("searchQuery", form);
  let searchKeyword = Form.useWatch("searchKeyword", form);

  const fetchUsers = () => {
    const request = {
      page: current,
      size: 10,
      gender: filter === "전체" ? null : filter,
      startCreatedDate: date,
      endCreatedDate,
      isDeleted: null,
    };
    request[`${searchQuery}`] = searchKeyword;
    request[`${searchItem.searchQuery}`] = searchItem.searchKeyword;

    userAPI.getUsers(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setUsers(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
              regTime: dateToString(r.regTime),
              last_login: dateToString(r.last_login),
            };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [current, filter, date, endCreatedDate, searchQuery, searchKeyword]);

  const onSearch = (e) => {
    if (!searchQuery) {
      message.error("검색조건을 선택해주세요.");
      return;
    }

    if (!searchKeyword) {
      message.error("검색 키워드를 입력해주세요.");
      return;
    }

    const newSearchItem = {
      ...searchItem,
      searchQuery: searchQuery,
      searchKeyword: searchKeyword,
    };
    setSearchItem(newSearchItem);

    setCurrent(1);
    localStorage.setItem("page", 1);

    setLoading(true);
    fetchUsers();
  };

  const onDelete = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    userAPI
      .deleteUsers({
        selectedItems: selectedItems?.map((i) => i.id),
        resignReason,
      })
      .then((res) => {
        if (res.status === 204) {
          fetchUsers();
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        }
      });
  };

  // 회원번호, 닉네임, 가입채널, 가입년월일시, 최종로그인년월일시, 마케팅동의여부
  const columns = [
    {
      title: "회원 번호",
      dataIndex: "id",
      width: "8%",
    },
    {
      title: "이름",
      dataIndex: "name",
      width: "8%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "닉네임",
      dataIndex: "nickName",
      width: "10%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "소셜로그인 타입",
      dataIndex: "snsType",
      width: "10%",
    },
    {
      title: "성별",
      dataIndex: "gender",
      width: "10%",
    },
    {
      title: "본인 인증",
      dataIndex: "isPhoneAuth",
      width: "10%",
      render: (val) => {
        return val ? '예' : '아니오'
      },
    },
    {
      title: "성인 인증",
      dataIndex: "isAdult",
      width: "10%",
      render: (val) => {
        return val ? '예' : '아니오'
      },
    },
    {
      title: "가입일시",
      dataIndex: "date_joined",
      width: "20%",
      render: (val) => {
        return dateToString(val);
      },
    },
    {
      title: "최근접속",
      dataIndex: "last_login",
      width: "20%",
      render: (val) => {
        return dateToString(val);
      },
    },
  ];

  const searchQueries = [
    {
      title: "이름",
      dataIndex: "name",
    },
    {
      title: "닉네임",
      dataIndex: "nickName",
    },
    {
      title: "가입 채널",
      dataIndex: "snsType",
    },
  ];
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        <p>선택한 항목을 삭제하시겠습니까?</p>
        <p>삭제하시려면 탈퇴 사유 선택 후 삭제 버튼을 눌러주세요.</p>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="회원정보 목록"
        subTitle={`총 ${total}명의 회원이 존재합니다.`}
      />
      <FlexRowContainer style={{ marginTop: "30px" }}>
        <Button
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            const newSearchItem = {
              ...searchItem,
              searchQuery: null,
              searchKeyword: null,
            };
            setSearchItem(newSearchItem);

            setCurrent(1);
            localStorage.setItem("page", 1);
            setFilter("전체");
            setDate(null);
            setEndCreatedDate(null);
            form.setFieldValue("searchQuery", null);
            form.setFieldValue("searchKeyword", null);
          }}
        >
          검색 조건 초기화
        </Button>
      </FlexRowContainer>
      <Form form={form} onFinish={onSearch}>
        <FlexRowContainer style={{ marginTop: "30px" }}>
          <FlexRowQuarterContainer>
            <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>
              <Select placeholder="검색조건">
                {searchQueries?.map((c) => {
                  return (
                    <Select.Option key={c.dataIndex} value={c.dataIndex}>
                      {c.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchKeyword"
              initialValue={searchItem.searchKeyword}
              style={{ width: "300px" }}
            >
              <Input placeholder="검색 키워드를 입력하세요" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              />
            </Form.Item>
          </FlexRowQuarterContainer>
        </FlexRowContainer>
      </Form>
      <Form form={dateForm}>
        <FlexRowContainer>
          <Form.Item label="가입일시">
            <div style={{ display: "flex" }}>
              <DatePicker
                onChange={(moment) => {
                  setDate(dateToCompactString(moment?._d));
                }}
              />
              <div style={{ margin: "0 10px" }}>~</div>
              <DatePicker
                onChange={(moment) => {
                  setEndCreatedDate(dateToCompactString(moment?._d));
                }}
              />
            </div>
          </Form.Item>
        </FlexRowContainer>
      </Form>
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["전체", "남", "여"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={users}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/user/info/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
};
