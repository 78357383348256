import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Segmented,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as communityAPI from "../../../apis/community";

import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";

export default function QnaList() {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const [form] = Form.useForm();
  const searchQuery = Form.useWatch("searchQuery", form);
  const searchKeyword = Form.useWatch("searchKeyword", form);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [qnas, setQnas] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState("문의하기");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchQnas = () => {
    const request = {
      page: current,
      size: 10,
    };

    if (filter === "문의하기") {
      communityAPI
        .getInquiries(request)
        .then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setQnas(
              res.data.data?.map((r) => {
                return { ...r, key: r.id };
              })
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      communityAPI
        .getFAQs(request)
        .then((res) => {
          if (res.data.linusCode === 1) {
            setTotal(res.data.page.count);
            setQnas(
              res.data.data?.map((r) => {
                return { ...r, key: r.id };
              })
            );
            setLoading(false);
          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  useEffect(() => {
    fetchQnas();
  }, [current, filter]);

  /* 
  글번호, 작성자닉네임, 제목, 등록년월일시, 최종수정년월일시, 좋아요수, 스크랩수, 공유하기수
  */

  const columns = [
    {
      title: "글번호",
      dataIndex: "id",
      width: "7%",
    },
    {
      title: filter === "문의하기" ? "문의종류" : "질문",
      dataIndex: filter === "문의하기" ? "type" : "title",
      width: "13%",
    },
    {
      title: filter === "문의하기" ? "내용" : "답변",
      dataIndex: "content",
      width: "50%",
    },
    {
      title: filter === "문의하기" ? "문의일시" : "등록일시",
      dataIndex: "createdDate",
      render: (date) => dateToString(date),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onFinish = (values) => {};

  const onDelete = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    communityAPI
      .deletePosts({ selectedItems: selectedItems?.map((i) => i.id) })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchQnas();
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  // 작성자닉네임, 제목
  const searchQueries = [
    {
      title: "제목",
      dataIndex: "title",
    },
    {
      title: "작성자 닉네임",
      dataIndex: "writerNickName",
    },
    {
      title: "작성자 이메일",
      dataIndex: "writerEmail",
    },
  ];

  const onEdit = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    navigate(`/community/faq/edit/${selectedItems[0].id}`);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onShowDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalVisible(true);
  };

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <PageHeader className="site-page-header" title="고객 관리" />
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["문의하기", "FAQ"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      {filter === "FAQ" && (
        <FlexRowReverseContainer>
          <Button danger onClick={onShowDeleteModal}>
            선택 삭제
          </Button>
          <Button style={{ margin: "0 10px" }} onClick={onEdit}>
            선택 수정
          </Button>
          <Button
            type="primary"
            onClick={() => {
              navigate(`/community/faq/new`);
            }}
          >
            추가
          </Button>
        </FlexRowReverseContainer>
      )}
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={qnas}
        rowSelection={
          filter === "FAQ" && {
            type: "checkbox",
            ...rowSelection,
          }
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (filter === "FAQ") {
                navigate(`/community/faq/${record.id}`);
                return;
              }

              navigate(`/community/customer/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
}
