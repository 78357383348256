import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import styled from "styled-components";
import * as userAPI from "../../apis/user";
import * as etcAPI from "../../apis/etc";
import * as fileAPI from "../../apis/file";

import {
  Button,
  DatePicker,
  Form,
  message,
  PageHeader,
  Segmented,
  Table,
} from "antd";
import Pagination from "react-js-pagination";

import { dateToCompactString, dateToString } from "../../utils/common";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../components/Common/Container.styles";

const ChartDesc = styled.div`
  background-color: ${(props) => {
    return props.color;
  }};
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  margin-right: 3px;
  border-radius: 10px;
`;

export default function HomePage() {
  const colors = [
    "#f6fafd",
    "#e2eff9",
    "#cce4f5",
    "#b5d8f0",
    "#9bcaeb",
    "#7dbae5",
    "#5aa7de",
    "#2d8fd5",
  ];

  const [loading, setLoading] = useState(true);
  const [hLoading, setHLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [histories, setHistories] = useState([]);
  const [totalHistory, setTotalHistory] = useState(0);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [startCreatedDate, setStartCreatedDate] = useState(null);
  const [endCreatedDate, setEndCreatedDate] = useState(null);

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [dateForm] = Form.useForm();
  const [filter, setFilter] = useState("전체");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  useEffect(() => {
    const request = {
      page: current,
      size: 20,
      startCreatedDate,
      endCreatedDate,
    };

    etcAPI.getHistories(request).then((res) => {
      if (res.data.linusCode === 1) {
        setHistories(res.data.data);
        setTotalHistory(res.data.page.count);
        console.log(res.data.page.count);
        setHLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  }, [current, startCreatedDate, endCreatedDate]);

  useEffect(() => {
    userAPI.getMainUser().then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.count);
        setUsers(
          Object.keys(res.data.data[0]).map((r, idx) => {
            return {
              ...r,
              key: r.id,
              title: `${r} 대`,
              color: colors[idx],
              value: res.data.data[0][r],
            };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "경로",
      dataIndex: "path",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "유저아이디",
      dataIndex: "user",
      width: "10%",
      render: (val) => {
        return val?.id ?? "-";
      },
    },
    {
      title: "유저이메일",
      dataIndex: "user",
      width: "10%",
      render: (val) => {
        return val?.email ?? "-";
      },
    },
    {
      title: "호출일시",
      dataIndex: "createdDate",
      width: "15%",
      render: (val) => {
        return dateToString(val);
      },
    },
  ];

  const downloadExcelFile = (number, name) => {
    fileAPI.downloadHistoryExcelFile(number, name).catch((error) => {
      if (error.response.data.detail) {
        message.error(error.response.data.detail);
      } else if (error.response.status < 500) {
        for (let key of Object.keys(error.response.data)) {
          message.error(`${key}: ${error.response.data[key]}`);
        }
      } else {
        message.error("오류가 발생했습니다.");
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!loading && (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <PieChart
              data={users}
              radius={40}
              animate={true}
              label={(labelRenderProps) => {
                return `${Math.round(labelRenderProps.dataEntry.percentage)}%`;
              }}
              labelStyle={(idx) => {
                return { color: "black", fontSize: "4.5px" };
              }}
              labelPosition={60}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "0 20px",
            }}
          >
            <h3
              style={{
                fontWeight: 700,
                marginBottom: "0",
              }}
            >
              총 회원
            </h3>

            <p
              style={{
                fontWeight: 900,
                fontSize: "24px",
              }}
            >
              {total}명
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            {users?.map((u) => {
              return (
                <div style={{ display: "flex" }} key={u.title}>
                  <ChartDesc color={u.color} />
                  <span>{u.title}</span>
                  <span>{`  (${u.value}명)`}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!hLoading && (
        <div style={{ marginTop: "40px" }}>
          <PageHeader
            className="site-page-header"
            title="사용기록 목록"
            subTitle={`총 ${totalHistory}개의 사용기록이 존재합니다.`}
          />
          <Form form={dateForm}>
            <FlexRowContainer>
              <Form.Item label="기간">
                <div style={{ display: "flex" }}>
                  <DatePicker
                    onChange={(moment) => {
                      setStartCreatedDate(dateToCompactString(moment?._d));
                    }}
                  />
                  <div style={{ margin: "0 10px" }}>~</div>
                  <DatePicker
                    onChange={(moment) => {
                      setEndCreatedDate(dateToCompactString(moment?._d));
                    }}
                  />
                </div>
              </Form.Item>
            </FlexRowContainer>
          </Form>
          <FlexRowReverseContainer style={{ marginBottom: "20px" }}>
            <Button
              onClick={(e) => {
                downloadExcelFile(4, "일자별 페이지뷰");
              }}
              style={{ marginLeft: "10px" }}
            >
              일자별 페이지뷰
            </Button>
            <Button
              onClick={(e) => {
                downloadExcelFile(3, "일자별 이용시간");
              }}
              style={{ marginLeft: "10px" }}
            >
              일자별 이용시간
            </Button>
            <Button
              onClick={(e) => {
                downloadExcelFile(2, "일자별 로그인 수");
              }}
              style={{ marginLeft: "10px" }}
            >
              일자별 로그인 수
            </Button>
            <Button
              onClick={(e) => {
                downloadExcelFile(1, "엑셀 다운로드");
              }}
            >
              엑셀 다운로드
            </Button>
          </FlexRowReverseContainer>
          <Table
            pagination={false}
            loading={loading}
            columns={columns}
            dataSource={histories}
            rowSelection={{
              type: "checkbox",
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {}, // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {}, // mouse enter row
                onMouseLeave: (event) => {}, // mouse leave row
              };
            }}
          />
          <FlexRowEndContainer style={{ marginTop: "30px" }}>
            <Pagination
              activePage={current}
              itemsCountPerPage={20}
              totalItemsCount={totalHistory}
              pageRangeDisplayed={10}
              onChange={onChange}
            />
          </FlexRowEndContainer>
        </div>
      )}
    </div>
  );
}
