import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Segmented,
  Radio,
  Checkbox,
  DatePicker,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as systemAPI from "../../../apis/system";
import { dateToCompactString, dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";
import { Option } from "antd/lib/mentions";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";
import TextArea from "antd/lib/input/TextArea";

export const List = () => {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [festivals, setFestivals] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState("전체");

  const [startCreatedDate, setStartCreatedDate] = useState(null);
  const [endCreatedDate, setEndCreatedDate] = useState(null);

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [form] = Form.useForm();
  const [dateForm] = Form.useForm();

  const searchQuery = Form.useWatch("searchQuery", form);
  const searchKeyword = Form.useWatch("searchKeyword", form);

  const fetchFestivals = () => {
    const request = {
      page: current,
      size: 10,
      festivalType: filter === "전체" ? null : filter,
      startCreatedDate,
      endCreatedDate,
    };
    request[`${searchQuery}`] = searchKeyword;
    request[`${searchItem.searchQuery}`] = searchItem.searchKeyword;

    systemAPI.getFestivals(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setFestivals(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
              regTime: dateToString(r.regTime),
              last_login: dateToString(r.last_login),
            };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchFestivals();
  }, [
    current,
    filter,
    startCreatedDate,
    endCreatedDate,
    searchQuery,
    searchKeyword,
  ]);

  const onSearch = (e) => {
    if (!searchQuery) {
      message.error("검색조건을 선택해주세요.");
      return;
    }

    if (!searchKeyword) {
      message.error("검색 키워드를 입력해주세요.");
      return;
    }

    const newSearchItem = {
      ...searchItem,
      searchQuery: searchQuery,
      searchKeyword: searchKeyword,
    };
    setSearchItem(newSearchItem);

    setCurrent(1);
    localStorage.setItem("page", 1);

    setLoading(true);
    fetchFestivals();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // 페스티벌번호, 닉네임, 가입채널, 가입년월일시, 최종로그인년월일시, 마케팅동의여부
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
    },
    {
      title: "제목",
      dataIndex: "title",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "카테고리",
      dataIndex: "festivalType",
      width: "10%",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "MD Pick",
      dataIndex: "isMDPick",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
    {
      title: "발신여부",
      dataIndex: "",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
    {
      title: "지역",
      dataIndex: "address",
      width: "20%",
    },
    {
      title: "등록일시",
      dataIndex: "createdDate",
      width: "15%",
      render: (val) => {
        return dateToString(val);
      },
    },
  ];

  const searchQueries = [
    {
      title: "제목",
      dataIndex: "title",
    },
  ];

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onDelete = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    systemAPI
      .deleteEvents({ selectedItems: selectedItems?.map((i) => i.id) })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchFestivals();
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        } else {
          alert(res.data.data.msg);
        }
      });
  };

  const onShowDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalVisible(true);
  };

  const onEdit = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    navigate(`/system/festival/edit/${selectedItems[0].id}`);
  };

  const [pushForm] = Form.useForm();
  const [pushAlarm, setPushAlarm] = useState({});
  const category = Form.useWatch("category", pushForm);
  const [showPushModal, setShowPushModal] = useState(false);

  const onShowPushModal = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    setShowPushModal(true);
  };

  const onFinish = (values) => {
    systemAPI
      .createPushAlarm({
        ...values,
        festival: selectedItems[0].id,
      })
      .then((res) => {
        if (res.data.linusCode === 1) {
          message.success("푸시알림이 성공적으로 등록되었습니다.");
          setShowPushModal(false);
          setSelectedItems([]);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <Modal
        width={400}
        title="푸시 전송"
        visible={showPushModal}
        onOk={onFinish}
        onCancel={() => {
          setShowPushModal(false);
        }}
        footer={[]}
      >
        <Form
          form={pushForm}
          name="register"
          onFinish={onFinish}
          initialValues={pushAlarm}
          scrollToFirstError
        >
          <Form.Item
            label=""
            name="isFestivalLikeUser"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>좋아요한 멤버</Checkbox>
          </Form.Item>
          <Form.Item
            label=""
            name="isFestivalPlayGroupUser"
            valuePropName="checked"
          >
            <Checkbox>페스티벌 모임</Checkbox>
          </Form.Item>

          <Form.Item label="발송 유형" name="category">
            <Radio.Group>
              {["실시간 발송", "예약 발송"].map((i) => (
                <Radio value={i} key={i}>
                  {i}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {category === "예약 발송" && (
            <Form.Item label="예약 시간" name="reservationTime">
              <input type={"datetime-local"} />
            </Form.Item>
          )}
          <Form.Item
            name="title"
            label="제목"
            rules={[
              {
                required: true,
                message: "제목을 입력해주세요.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="content" label="내용">
            <TextArea rows={3} placeholder="글 내용을 입력해주세요." />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/system/festival`);
                }}
                style={{ margin: "0 10px" }}
              >
                목록
              </Button>
              <Button type="primary" htmlType="submit">
                저장
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="페스티벌 목록"
        subTitle={`총 ${total}개의 페스티벌이 존재합니다.`}
      />
      <FlexRowContainer style={{ marginTop: "30px" }}>
        <Button
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            const newSearchItem = {
              ...searchItem,
              searchQuery: null,
              searchKeyword: null,
            };
            setSearchItem(newSearchItem);

            setCurrent(1);
            localStorage.setItem("page", 1);
            setFilter("전체");
            setStartCreatedDate(null);
            setEndCreatedDate(null);
            form.setFieldValue("searchQuery", null);
            form.setFieldValue("searchKeyword", null);
          }}
        >
          검색 조건 초기화
        </Button>
      </FlexRowContainer>
      <Form form={form} onFinish={onSearch}>
        <FlexRowContainer style={{ marginTop: "30px" }}>
          <FlexRowQuarterContainer>
            <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>
              <Select placeholder="검색조건">
                {searchQueries?.map((c) => {
                  return (
                    <Select.Option key={c.dataIndex} value={c.dataIndex}>
                      {c.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchKeyword"
              initialValue={searchItem.searchKeyword}
              style={{ width: "300px" }}
            >
              <Input placeholder="검색 키워드를 입력하세요" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
              />
            </Form.Item>
          </FlexRowQuarterContainer>
        </FlexRowContainer>
      </Form>
      <Form form={dateForm}>
        <FlexRowContainer>
          <Form.Item label="등록일시">
            <div style={{ display: "flex" }}>
              <DatePicker
                onChange={(moment) => {
                  setStartCreatedDate(dateToCompactString(moment?._d));
                }}
              />
              <div style={{ margin: "0 10px" }}>~</div>
              <DatePicker
                onChange={(moment) => {
                  setEndCreatedDate(dateToCompactString(moment?._d));
                }}
              />
            </div>
          </Form.Item>
        </FlexRowContainer>
      </Form>
      <FlexRowContainer style={{ marginBottom: "30px" }}>
        <Segmented
          options={["전체", "Culture", "Music", "Food", "Concert"]}
          value={filter}
          onChange={onChangeFilter}
        />
      </FlexRowContainer>
      <FlexRowReverseContainer>
        <Button danger onClick={onShowDeleteModal}>
          선택 삭제
        </Button>
        <Button style={{ margin: "0 10px" }} onClick={onEdit}>
          선택 수정
        </Button>
        <Button
          style={{ margin: "0 0 0 10px" }}
          type="primary"
          onClick={() => {
            navigate(`/system/festival/new`);
          }}
        >
          추가
        </Button>
        <Button onClick={onShowPushModal}>푸시 보내기</Button>
      </FlexRowReverseContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={festivals}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/system/festival/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
};
