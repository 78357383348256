import { atom, RecoilRoot, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: localStorage, // configurate which stroage will be used to store the data
});

export const searchItemState = atom({
  key: "search",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const authItemState = atom({
  key: "auth",
  default: { isAuthed: false },
  effects_UNSTABLE: [persistAtom],
});
