import client from "./client";

/* 공지사항 */

export const createNotice = ({ title, content, isMain }) =>
  client.post(`/notice/`, {
    title,
    content,
    isMain,
  });

export const getNotice = ({ id }) => client.get(`/notice/${id}/`);

export const getNotices = ({ page, size }) =>
  client.get(`/notice/`, {
    params: {
      page: page,
      size: size,
    },
  });

export const editNotice = ({ id, title, content, isMain }) =>
  client.put(`/notice/${id}/`, {
    title,
    content,
    isMain,
  });

export const deleteNotices = ({ selectedItems }) =>
  client.delete(`/notice-multi-delete/`, { data: { ids: selectedItems } });

export const getHistories = ({
  page,
  size,
  isExcel,
  startCreatedDate,
  endCreatedDate,
}) => {
  return client.get("/used-history/", {
    params: {
      page,
      size,
      isExcel,
      startCreatedDate,
      endCreatedDate,
    },
  });
};
