import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Table,
  Descriptions,
  message,
  Skeleton,
  Image,
} from "antd";
import { FlexRowReverseContainer } from "../../Common/Container.styles";
import { Spin } from "antd";

import * as systemAPI from "../../../apis/system";
import { dateToString } from "../../../utils/common";

export default function Detail() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [pushAlarm, setPushAlarm] = useState(null);

  const fetchPushAlarm = () => {
    systemAPI
      .getPushAlarm({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setPushAlarm(res.data.data);
          setLoading(false);
        }else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchPushAlarm();
  }, [params.id]);

  const boothColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "부스명",
      dataIndex: "title",
    },
    {
      title: "위도",
      dataIndex: "lat",
    },
    {
      title: "경도",
      dataIndex: "lon",
    },
    {
      title: "푸시 제목",
      dataIndex: "address",
    },
    {
      title: "푸시 내용",
      dataIndex: "interestMusic",
    },
    {
      title: "푸시 예약일시",
      dataIndex: "interestMusic",
    },
  ];

  const pushColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "푸시 제목",
      dataIndex: "title",
    },
    {
      title: "푸시 내용",
      dataIndex: "content",
    },
    {
      title: "발송 종류",
      dataIndex: "category",
    },
    {
      title: "예약 일시",
      dataIndex: "reservationTime",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "발송 일시",
      dataIndex: "sentTime",
      render: (val) => {
        return val ?? "-";
      },
    },
    {
      title: "발신 여부",
      dataIndex: "isSent",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
    {
      title: "좋아요한 멤버",
      dataIndex: "isPushAlarmLikeUser",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
    // {
    // title: '스크랩한 멤버',
    // dataIndex: 'isPushAlarmLikeUser',
    // render: (val) => {
    //     return val ? 'O' : 'X'
    //     },
    // },
    {
      title: "푸시알람 모임",
      dataIndex: "isPushAlarmPlayGroupUser",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="푸시알람 조회" />
      <FlexRowReverseContainer>
        {pushAlarm && pushAlarm?.category !== "실시간 발송" && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/system/push-alarm/edit/${params.id}`);
            }}
          >
            수정
          </Button>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/system/push-alarm`);
          }}
          style={{ margin: "0 10px" }}
        >
          목록
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      {loading && (
        <>
          <Skeleton active />
          <Skeleton.Image active />
        </>
      )}
      {!loading && (
        <>
          <Descriptions
            labelStyle={{ fontWeight: 800 }}
            bordered
            column={6}
            layout="vertical"
          >
            <Descriptions.Item label="ID" span={2}>
              {pushAlarm.id ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="등록일시" span={2}>
              {dateToString(pushAlarm.createdDate)}
            </Descriptions.Item>
            <Descriptions.Item label="최종수정일시" span={2}>
              {dateToString(pushAlarm.updatedDate)}
            </Descriptions.Item>
            <Descriptions.Item label="예약일시" span={2}>
              {pushAlarm.reservationTime
                ? dateToString(pushAlarm.reservationTime)
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="발송일시" span={2}>
              {pushAlarm.sentTime ? dateToString(pushAlarm.sentTime) : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="발송종류" span={2}>
              {pushAlarm.category}
            </Descriptions.Item>
            <Descriptions.Item label="제목" span={2}>
              {pushAlarm.title ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="내용" span={4}>
              {pushAlarm.content ?? "-"}
            </Descriptions.Item>
            {pushAlarm.festival && (
              <>
                <Descriptions.Item label="좋아요한 멤버" span={2}>
                  {pushAlarm.isFestivalLikeUser ? "O" : "X"}
                </Descriptions.Item>
                {/* <Descriptions.Item label="스크랩한 멤버" span={2}>
              {pushAlarm.isFestivalLikeUser ? 'O' : 'X'}
            </Descriptions.Item> */}
                <Descriptions.Item label="페스티벌 모임" span={4}>
                  {pushAlarm.isFestivalPlayGroupUser ? "O" : "X"}
                </Descriptions.Item>
                <Descriptions.Item label="페스티벌 ID" span={2}>
                  {pushAlarm.festival?.id ?? "-"}
                </Descriptions.Item>
                <Descriptions.Item label="페스티벌 제목" span={4}>
                  {pushAlarm.festival?.title ?? "-"}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </>
      )}
    </div>
  );
}
