export const menus = [
  {
    mainMenu: { title: "회원", path: "user" },
    subMenus: [
      { title: "회원정보 조회", path: "info" },
      { title: "탈퇴회원 조회", path: "withdrawal" },
      { title: "계정 관리", path: "partner-user" },
      { title: "파트너사 정보", path: "partner" },
    ],
  },
  {
    mainMenu: { title: "앱 (시스템)", path: "system" },
    subMenus: [
      { title: "페스티벌 관리", path: "festival" },
      { title: "모임 관리", path: "play-group" },
      { title: "포인트 관리", path: "point" },
      { title: "푸시알림 관리", path: "push-alarm" },
      { title: "공지사항 관리", path: "notice" },
      { title: "이벤트 관리", path: "event" },
      { title: "강제 퇴장 기록", path: "drop-out" },
    ],
  },
  {
    mainMenu: { title: "커뮤니티", path: "community" },
    subMenus: [
      { title: "신고 관리", path: "report" },
      { title: "고객 관리", path: "customer" },
    ],
  },
  {
    mainMenu: { title: "쇼핑", path: "shopping" },
    subMenus: [
      { title: "상품 관리", path: "product" },
      { title: "FAQ 템플릿 관리", path: "faq" },
      { title: "배송 주문 관리", path: "order" },
      { title: "무배송 주문 관리", path: "no-delivery-order" },
      { title: "상품 문의 관리", path: "inquiry" },
      { title: "티켓 관리", path: "ticket" },
      { title: "상품 카테고리 관리", path: "category" },
      { title: "상품 검색 관리", path: "search" },
      { title: "상품 배너 관리", path: "banner" },
    ],
  },
];
