import React, { useEffect, useState } from "react";
import { Table, Divider, PageHeader, Button, message, Modal } from "antd";
import {
  FlexRowEndContainer,
  FlexRowReverseContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";

import * as etcAPI from "../../../apis/etc";
import { dateToString } from "../../../utils/common";

import Pagination from "react-js-pagination";

export default function NoticeList() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [notices, setNotices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const fetchNotices = () => {
    const request = {
      page: current,
      size: 10,
    };

    etcAPI.getNotices(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setNotices(
          res.data.data?.map((r) => {
            return { ...r, key: r.id };
          })
        );
        setLoading(false);
      }else {
        alert(res.data.data.msg);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchNotices();
  }, [current]);

  // 글번호, 공지제목, 작성자명, 등록년월일시, 최정수정년월일시
  const columns = [
    {
      title: "글번호",
      dataIndex: "id",
      width: "8%",
    },
    {
      title: "공지 제목",
      dataIndex: "title",
    },
    {
      title: "등록일시",
      dataIndex: "createdDate",
      render: (text) => dateToString(text),
      width: "18%",
    },
    {
      title: "최종수정일시",
      dataIndex: "updatedDate",
      render: (text) => dateToString(text),
      width: "18%",
    },
    {
      title: "메인노출",
      dataIndex: "isMain",
      render: (val) => {
        return val ? "O" : "X";
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onDelete = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      message.error("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    etcAPI
      .deleteNotices({ selectedItems: selectedItems?.map((i) => i.id) })
      .then((res) => {
        if (res.data.linusCode === 1) {
          fetchNotices();
          setSelectedItems([]);
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        }else {
          alert(res.data.data.msg);
        }
      });
  };

  const onEdit = (e) => {
    e.preventDefault();

    if (selectedItems.length !== 1) {
      message.error("하나의 항목을 선택 후 수정 버튼을 눌러주세요.");
      return;
    }

    navigate(`/system/notice/edit/${selectedItems[0].id}`);
  };

  const onCreate = (e) => {
    e.preventDefault();

    navigate(`/system/notice/new`);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onShowDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalVisible(true);
  };

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <PageHeader
        className="site-page-header"
        title="공지사항 관리"
        subTitle={`총 ${total}개의 공지사항이 존재합니다.`}
      />
      <FlexRowReverseContainer>
        <Button danger onClick={onShowDeleteModal}>
          선택 삭제
        </Button>
        <Button style={{ margin: "0 10px" }} onClick={onEdit}>
          선택 수정
        </Button>
        <Button type="primary" onClick={onCreate}>
          추가
        </Button>
      </FlexRowReverseContainer>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={notices}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/system/notice/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
}
