import React, { useEffect, useState } from "react";
import {
  Table,
  Divider,
  PageHeader,
  Button,
  Form,
  Select,
  Input,
  Segmented,
  message, Modal, DatePicker,
} from "antd";
import {
  FlexRowContainer,
  FlexRowEndContainer,
  FlexRowQuarterContainer,
} from "../../Common/Container.styles";

import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import * as communityAPI from "../../../apis/community";
import {dateToCompactString, dateToLongString, dateToString, getDiscountRate} from "../../../utils/common";

import Pagination from "react-js-pagination";
import { useRecoilState } from "recoil";
import { searchItemState } from "../../../states/atoms";
import * as shoppingAPI from "../../../apis/shopping";
import client from "../../../apis/client";
import {deleteFaqFormats} from "../../../apis/shopping";

export default function CategoryList() {
  const [searchItem, setSearchItem] = useRecoilState(searchItemState);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [date, setDate] = useState(null);
  const [endCreatedDate, setEndCreatedDate] = useState(null);

  const [current, setCurrent] = useState(
    Number(localStorage.getItem("page")) ?? 1
  );
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState("전체");

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
    onChange(1);
  };

  const onChange = (page) => {
    setCurrent(page);
    localStorage.setItem("page", page);
  };

  const [form] = Form.useForm();
  const [dateForm] = Form.useForm();
  let searchQuery = Form.useWatch("searchQuery", form);
  let searchKeyword = Form.useWatch("searchKeyword", form);

  const fetchItems = () => {
    const request = {
      page: current,
      size: 10,
      isDeleted: null,
      isDelivery: 1,
    };
    request[`${searchQuery}`] = searchKeyword;
    request[`${searchItem.searchQuery}`] = searchItem.searchKeyword;

    shoppingAPI.getCategories(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setItems(
          res.data.data?.map((r) => {
            return {
              ...r,
              key: r.id,
              regTime: dateToString(r.regTime),
              last_login: dateToString(r.last_login),
            };
          })
        );
        setLoading(false);
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  useEffect(() => {
    fetchItems();
  }, [current, filter, date, endCreatedDate, searchQuery, searchKeyword]);

  const onSearch = (e) => {
    if (!searchQuery) {
      message.error("검색조건을 선택해주세요.");
      return;
    }

    if (!searchKeyword) {
      message.error("검색 키워드를 입력해주세요.");
      return;
    }

    const newSearchItem = {
      ...searchItem,
      searchQuery: searchQuery,
      searchKeyword: searchKeyword,
    };
    setSearchItem(newSearchItem);

    setCurrent(1);
    localStorage.setItem("page", 1);

    setLoading(true);
    fetchItems();
  };

  const onDelete = (id) => {
    shoppingAPI
      .deleteCategory({
        id
      })
      .then((res) => {
        if (res.data?.linusCode === 1) {
          fetchItems();
          message.success("성공적으로 삭제되었습니다.");
          setDeleteModalVisible(false);
        } else {
          alert(res?.data?.data?.msg)
        }
      });
  };

  // 회원번호, 닉네임, 가입채널, 가입년월일시, 최종로그인년월일시, 마케팅동의여부
  const columns = [
    {
      title: "카테고리 ID",
      dataIndex: "id",
      width: "8%",
    },
    {
      title: "카테고리 명",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "수정 / 삭제",
      dataIndex: "",
      width: "10%",
      render: (val, obj) => {
        return (
          <div align={'right'}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/shopping/category/edit/${obj?.id}/`);
              }}
            >
              수정
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onDelete(obj.id)
              }}
            >
              삭제
            </Button>
          </div>
        )
      }
    },


  ];

  const searchQueries = [
    {
      title: "주문번호",
      dataIndex: "number",
    },
    {
      title: "송장번호",
      dataIndex: "deliveryNumber",
    },
    {
      title: "이메일",
      dataIndex: "userEmail",
    },
    {
      title: "폰번호",
      dataIndex: "userPhone",
    },
    {
      title: "닉네임",
      dataIndex: "userNickname",
    },
  ];

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <div>
      <Modal
        width={400}
        title="삭제 확인"
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={(e) => {
              e.preventDefault();
              setDeleteModalVisible(false);
            }}
          >
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={onDelete}>
            삭제
          </Button>,
        ]}
      >
        <p>선택한 항목을 삭제하시겠습니까?</p>
        <p>삭제하시려면 탈퇴 사유 선택 후 삭제 버튼을 눌러주세요.</p>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="카테고리 목록"
        subTitle={`총 ${total}개의 카테고리가 존재합니다.`}
      />
      {/*<FlexRowContainer style={{ marginTop: "30px" }}>*/}
      {/*  <Button*/}
      {/*    type="primary"*/}
      {/*    onClick={(e) => {*/}
      {/*      e.preventDefault();*/}
      {/*      const newSearchItem = {*/}
      {/*        ...searchItem,*/}
      {/*        searchQuery: null,*/}
      {/*        searchKeyword: null,*/}
      {/*      };*/}
      {/*      setSearchItem(newSearchItem);*/}

      {/*      setCurrent(1);*/}
      {/*      localStorage.setItem("page", 1);*/}
      {/*      setFilter("전체");*/}
      {/*      setDate(null);*/}
      {/*      setEndCreatedDate(null);*/}
      {/*      form.setFieldValue("searchQuery", null);*/}
      {/*      form.setFieldValue("searchKeyword", null);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    검색 조건 초기화*/}
      {/*  </Button>*/}
      {/*</FlexRowContainer>*/}
      {/*<Form form={form} onFinish={onSearch}>*/}
      {/*  <FlexRowContainer style={{ marginTop: "30px" }}>*/}
      {/*    <FlexRowQuarterContainer>*/}
      {/*      <Form.Item name="searchQuery" initialValue={searchItem.searchQuery}>*/}
      {/*        <Select placeholder="검색조건">*/}
      {/*          {searchQueries?.map((c) => {*/}
      {/*            return (*/}
      {/*              <Select.Option key={c.dataIndex} value={c.dataIndex}>*/}
      {/*                {c.title}*/}
      {/*              </Select.Option>*/}
      {/*            );*/}
      {/*          })}*/}
      {/*        </Select>*/}
      {/*      </Form.Item>*/}
      {/*      <Form.Item*/}
      {/*        name="searchKeyword"*/}
      {/*        initialValue={searchItem.searchKeyword}*/}
      {/*        style={{ width: "300px" }}*/}
      {/*      >*/}
      {/*        <Input placeholder="검색 키워드를 입력하세요" />*/}
      {/*      </Form.Item>*/}
      {/*      <Form.Item>*/}
      {/*        <Button*/}
      {/*          type="primary"*/}
      {/*          icon={<SearchOutlined />}*/}
      {/*          htmlType="submit"*/}
      {/*        />*/}
      {/*      </Form.Item>*/}
      {/*    </FlexRowQuarterContainer>*/}
      {/*  </FlexRowContainer>*/}
      {/*</Form>*/}
      {/*<Form form={dateForm}>*/}
      {/*  <FlexRowContainer>*/}
      {/*    <Form.Item label="가입일시">*/}
      {/*      <div style={{ display: "flex" }}>*/}
      {/*        <DatePicker*/}
      {/*          onChange={(moment) => {*/}
      {/*            setDate(dateToCompactString(moment?._d));*/}
      {/*          }}*/}
      {/*        />*/}
      {/*        <div style={{ margin: "0 10px" }}>~</div>*/}
      {/*        <DatePicker*/}
      {/*          onChange={(moment) => {*/}
      {/*            setEndCreatedDate(dateToCompactString(moment?._d));*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </Form.Item>*/}
      {/*  </FlexRowContainer>*/}
      {/*</Form>*/}
      {/*<FlexRowContainer style={{ marginBottom: "30px" }}>*/}
      {/*  <Segmented*/}
      {/*    options={["전체", "남", "여"]}*/}
      {/*    value={filter}*/}
      {/*    onChange={onChangeFilter}*/}
      {/*  />*/}
      {/*</FlexRowContainer>*/}
      <div align={'right'}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shopping/category/new/`);
          }}
        >
          등록
        </Button>
      </div>
      <Divider />
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={items}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // navigate(`/shopping/order/info/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        style={{ cursor: "pointer" }}
      />
      <FlexRowEndContainer style={{ marginTop: "30px" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          onChange={onChange}
        />
      </FlexRowEndContainer>
    </div>
  );
}
