import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InfoDetail from "../../components/Shopping/NoDeliveryOrder/InfoDetail";
import NoDeliveryOrderList from "../../components/Shopping/NoDeliveryOrder/NoDeliveryOrderList";

export default function NoDeliveryOrderPage() {
  return (
    <Routes>
      <Route path="/" element={<NoDeliveryOrderList />} />
      <Route path="/info/:id" element={<InfoDetail />} />
    </Routes>
  );
}
