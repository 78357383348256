import client from "./client";

export const saveOptionImage = (id, image) => {
  let formData = new FormData();
  formData.append("images", image);
  return client.post(`/product/${id}/images/multi-save/`, formData);
};

export const saveProductImage = (image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/product-image/`, formData);
};

export const deleteProductImage = (id) => {
  return client.delete(`/product-image/${id}/`);
};


export const saveProductContentImage = (image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/product-content-image/`, formData);
};

export const deleteProductContentImage = (id) => {
  return client.delete(`/product-content-image/${id}/`);
};


export const getProduct = ({ id }) => client.get(`/product/${id}/`);

export const getProducts = ({
                           page,
                           size,
                           name,
                           content,
                           isOption,
                           isDeleted,
                           createdDate,
                           deletedDate,
                         }) =>
  client.get("/product/", {
    params: {
      page: page,
      size: size,
      name,
      content,
      isOption,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });


export const deleteProducts = ({ selectedItems }) =>
  client.delete(`/product-multi-delete/`, {
    data: { ids: selectedItems },
  });


export const createProduct = ({
                               images,
                               contentImages,
                               category,
                               name,
                               content,
                               price,
                               discountPrice,
                               deliveryFee,
                                deliveryPeriod,
                                startDate,
                                endDate,
                                ticketStartDate,
                                ticketEndDate,
                                isMDPick,
                                isAdult,
                                usedMaxPoint,
                                earnedPoint,
                                isDelivery,
                                phone,
                                quantity,
                                tag,
                                productOptions,
                                faqs,
                             }) =>
  client.post(`/product/`, {
    images,
    contentImages,
    category,
    name,
    content,
    price,
    discountPrice,
    deliveryFee,
    deliveryPeriod,
    startDate,
    endDate,
    ticketStartDate,
    ticketEndDate,
    isMDPick,
    isAdult,
    usedMaxPoint,
    earnedPoint,
    isDelivery,
    phone,
    quantity,
    tag,
    productOptions,
    faqs
  });

export const editProduct = ({
                             id,
                              images,
                              contentImages,
                              category,
                              name,
                              content,
                              price,
                              discountPrice,
                              deliveryFee,
                              deliveryPeriod,
                              startDate,
                              endDate,
                              ticketStartDate,
                              ticketEndDate,
                              isMDPick,
                              isAdult,
                              usedMaxPoint,
                              earnedPoint,
                              isDelivery,
                              phone,
                              quantity,
                              tag,
                              productOptions,
                              faqs,
                           }) =>
  client.put(`/product/${id}/`, {
    images,
    contentImages,
    category,
    name,
    content,
    price,
    discountPrice,
    deliveryFee,
    deliveryPeriod,
    startDate,
    endDate,
    ticketStartDate,
    ticketEndDate,
    isMDPick,
    isAdult,
    usedMaxPoint,
    earnedPoint,
    isDelivery,
    phone,
    quantity,
    tag,
    productOptions,
    faqs,
  });


export const createOption = ({
                                index,
                                name,
                                price,
                                discountPrice,
                               ticketStartDate,
                               ticketEndDate,
                              }) =>
  client.post(`/product/`, {
    index,
    name,
    price,
    discountPrice,
    ticketStartDate,
    ticketEndDate,
  });

export const editOption = ({
                              id,
                              index,
                              name,
                              url,
                              isShow,
                              startDate,
                              endDate,
                              product,
                              event
                            }) =>
  client.put(`/product/${id}/`, {
    index,
    name,
    url,
    isShow,
    startDate,
    endDate,
    product,
    event
  });



export const getFaqFormat = ({ id }) => client.get(`/product-faq-format/${id}/`);

export const getFaqFormats = ({
                              page,
                              size,
                              category,
                              title,
                              contents,
                              isDeleted,
                              createdDate,
                              deletedDate,
                            }) =>
  client.get("/product-faq-format/", {
    params: {
      page: page,
      size: size,
      category,
      title,
      contents,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });

export const deleteFaqFormats = ({ selectedItems }) =>
  client.delete(`/product-faq-format-multi-delete/`, {
    data: { ids: selectedItems },
  });


export const createFaqFormat = ({
                                  category,
                                  title,
                                  contents,
                               }) =>
  client.post(`/product-faq-format/`, {
    category,
    title,
    contents,
  });

export const editFaqFormat = ({
                               id,
                               category,
                               title,
                               contents,
                             }) =>
  client.put(`/product-faq-format/${id}/`, {
    category,
    title,
    contents,
  });


export const getOrder = ({ id }) => client.get(`/order/${id}/`);

export const getOrders = ({
                                page,
                                size,
                                number,
                                deliveryNumber,
                                isDelivery,
                                state,
                                userEmail,
                                userPhone,
                                userNickname,
                                isDeleted,
                                createdDate,
                                deletedDate,
                              }) =>
  client.get("/order/", {
    params: {
      page: page,
      size: size,
      number,
      deliveryNumber,
      isDelivery,
      state,
      userEmail,
      userPhone,
      userNickname,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });

export const editOrder = ({
                              id,
                              state,
                              deliveryNumber,
                              deliveryCompany,
                              deliveryLink
                            }) =>
  client.put(`/order/${id}/`, {
    state,
    deliveryNumber,
    deliveryCompany,
    deliveryLink
  });


export const getInquiry = ({ id }) => client.get(`/inquiry/${id}/`);

export const getInquiries = ({
                            page,
                            size,
                            number,
                            deliveryNumber,
                            product,
                            userEmail,
                            userPhone,
                            userNickname,
                            isDeleted,
                            createdDate,
                            deletedDate,
                          }) =>
  client.get("/inquiry/", {
    params: {
      page: page,
      size: size,
      number,
      deliveryNumber,
      product,
      userEmail,
      userPhone,
      userNickname,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });


export const editInquiry = ({
                             id,
                             answer,
                           }) =>
  client.put(`/inquiry/${id}/`, {
    answer,
  });

export const getTicket = ({ id }) => client.get(`/product-code/${id}/`);

export const getTickets = ({
                               page,
                               size,
                               codeNumber,
                               userEmail,
                               userPhone,
                               userNickname,
                               isDeleted,
                               createdDate,
                               deletedDate,
                             }) =>
  client.get("/product-code/", {
    params: {
      page: page,
      size: size,
      codeNumber,
      userEmail,
      userPhone,
      userNickname,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });

export const editTicket = ({
                               id,
                               isUsed,
                               state,
                             }) =>
  client.put(`/product-code/${id}/`, {
    isUsed,
    state,
  });



export const getCategory = ({ id }) => client.get(`/category/${id}/`);


export const getCategories = ({
                             page,
                             size,
                             isDeleted,
                             createdDate,
                             deletedDate,
                           }) =>
  client.get("/category/", {
    params: {
      page: page,
      size: size,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });


export const createCategory = ({
                               name,
                             }) =>
  client.post(`/category/`, {
    name,
  });

export const editCategory = ({
                             id,
                             name,
                           }) =>
  client.put(`/category/${id}/`, {
    name,
  });


export const deleteCategory = ({ id }) =>
  client.delete(`/category/${id}/`);


export const getKeywords = ({
                                page,
                                size,
                                isDeleted,
                                createdDate,
                                deletedDate,
                              }) =>
  client.get("/famous-keyword/", {
    params: {
      page: page,
      size: size,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });

export const editKeyword = ({
                             id,
                             keyword,
                           }) =>
  client.put(`/famous-keyword/${id}/`, {
    keyword,
  });



export const getBanner = ({ id }) => client.get(`/banner/${id}/`);

export const getBanners = ({
                              page,
                              size,
                              name,
                              url,
                              isDeleted,
                              createdDate,
                              deletedDate,
                            }) =>
  client.get("/banner/", {
    params: {
      page: page,
      size: size,
      name,
      url,
      isDeleted,
      createdDate,
      deletedDate,
    },
  });


export const createBanner = ({
                              index,
                              name,
                              url,
                              isShow,
                              startDate,
                              endDate,
                              product,
                              event
                            }) =>
  client.post(`/banner/`, {
    index,
    name,
    url,
    isShow,
    startDate,
    endDate,
    product,
    event
  });

export const editBanner = ({
                            id,
                            index,
                            name,
                            url,
                            isShow,
                            startDate,
                            endDate,
                            product,
                            event
                          }) =>
  client.put(`/banner/${id}/`, {
    index,
    name,
    url,
    isShow,
    startDate,
    endDate,
    product,
    event
  });

export const deleteBanner = ({ id }) => client.delete(`/banner/${id}/`);


export const saveBannerImage = (id, image, callback) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/banner/${id}/image/`, formData).then(res => {
    callback && callback()
  });
};


export const createFaq = ({
                               category,
                               title,
                               contents,
                             }) =>
  client.post(`/product-faq/`, {
    category,
    title,
    contents,
  });

export const editFaq = ({
                             id,
                          category,
                          title,
                          contents,
                           }) =>
  client.put(`/product-faq/${id}/`, {
    category,
    title,
    contents,
  });
