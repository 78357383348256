import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageHeader,
  Button,
  Divider,
  Form,
  Input,
  message,
  Upload,
  Select,
  DatePicker,
  Radio,
  Image,
  Table,
} from "antd";

import * as systemAPI from "../../../apis/system";
import * as fileAPI from "../../../apis/file";

import {
  CloseOutlined,
  CheckOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export default function EventForm({ isEdit }) {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState([]);

  const [image, setImage] = useState([]);
  const [imageId, setImageId] = useState([]);

  const fsystemhEvent = () => {
    systemAPI
      .getEvent({ id: params.id })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setEvent(res.data.data);
          setImage(res.data.data.images);
          setImageId(res.data.data.imageIds);
          setLoading(false);
        } else {
          alert(res.data.data.msg);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fsystemhEvent();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    systemAPI
      .getEventIndex()
      .then((res) => {
        if (res.data.linusCode === 1) {
          setEvents(res.data.data);
        }
      })
      .catch((error) => {
        if (error.response.data.detail) {
          message.error(error.response.data.detail);
        } else if (error.response.status < 500) {
          for (let key of Object.keys(error.response.data)) {
            message.error(`${key}: ${error.response.data[key]}`);
          }
        } else {
          message.error("오류가 발생했습니다.");
        }
      });
  }, []);

  const onFinish = (values) => {
    if (isEdit) {
      systemAPI
        .editEvent({
          ...event,
          ...values,
          images: imageId,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              message.success("성공적으로 수정되었습니다.");
              navigate(`/system/event/${res.data.data.id}`);
            }
          } else {
            alert(res.data.data.msg);
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    } else {
      systemAPI
        .createEvent({
          ...values,
          images: imageId,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            if (res.data.linusCode === 1) {
              message.success("성공적으로 수정되었습니다.");
              navigate(`/system/event/${res.data.data.id}`);
            } else {
              message.error(res.data.data.msg);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            message.error(error.response.data.detail);
          } else if (error.response.status < 500) {
            for (let key of Object.keys(error.response.data)) {
              message.error(`${key}: ${error.response.data[key]}`);
            }
          } else {
            message.error("오류가 발생했습니다.");
          }
        });
    }
  };

  const onUpload = (img) => {
    systemAPI.saveEventImage(img).then((res) => {
      if (res.data.linusCode === 1) {
        setImage([...image, res.data.data.image]);
        setImageId([...imageId, res.data.data.id]);
      } else {
        alert(res.data.data.msg);
      }
    });

    return false;
  };

  const columns = [
    {
      title: "순서",
      dataIndex: "index",
      width: "10%",
    },
    {
      title: "이미지",
      dataIndex: "images",
      render: (val) => <Image src={val} />,
      width: "20%",
    },
    {
      title: "이벤트 제목",
      dataIndex: "title",
      width: "25%",
    },
    {
      title: "이벤트 내용",
      dataIndex: "content",
    },
    {
      title: "URL 이동",
      width: "15%",
      render: (val) => {
        return (
          <div>
            <a href={val.url} target="_blank" rel="noreferrer">
              이동
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title={`이벤트 ${isEdit ? "수정" : "추가"}`}
          subTitle={`작성 후 저장 버튼을 눌러주세요.`}
        />
        <Divider />
        {loading && <></>}
      </div>
      {!loading && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={event}
              scrollToFirstError
              style={{ width: "50%" }}
            >
              <Form.Item
                name="title"
                label="이벤트 제목"
                rules={[
                  {
                    required: true,
                    message: "이벤트 제목을 입력해주세요.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="content" label="이벤트 내용">
                <TextArea rows={10} placeholder="내용을 입력해주세요." />
              </Form.Item>

              <Form.Item name="cautionContent" label="유의사항">
                <TextArea rows={10} placeholder="내용을 입력해주세요." />
              </Form.Item>

              <Form.Item name="startDateTime" label="시작일시">
                <input type={"datetime-local"} />
              </Form.Item>

              <Form.Item name="endDateTime" label="종료일시">
                <input type={"datetime-local"} />
              </Form.Item>

              <Form.Item label="유형" name="type">
                <Radio.Group>
                  {["오프라인", "온라인"].map((i) => (
                    <Radio value={i} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="포인트 유형" name="pointType">
                <Radio.Group>
                  {["적립형", "차감형"].map((i) => (
                    <Radio value={i} key={i}>
                      {i}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="포인트" name="point">
                <Input type="number" />
              </Form.Item>

              <h3>현재 메인 배너 순서</h3>

              <Table
                pagination={false}
                columns={columns}
                dataSource={events}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {}, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                  };
                }}
                style={{ marginBottom: "40px" }}
              />

              <Form.Item label="메인 배너 순서" name="index">
                <Input type="number" />
              </Form.Item>

              <Form.Item name="url" label="외부 링크">
                <Input />
              </Form.Item>

              <Form.Item label="이미지 업로드">
                <Upload
                  beforeUpload={onUpload}
                  showUploadList={false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                </Upload>
              </Form.Item>

              {image?.length > 0 && (
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {image?.map((i, idx) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Image
                        width={100}
                        key={idx}
                        src={i}
                        alt="모임사진"
                        style={{ maxWidth: "400px" }}
                      />
                      <Button
                        danger
                        onClick={() => {
                          let imageCopy = [...image];
                          let imageIdCopy = [...imageId];

                          imageCopy.splice(idx, 1);
                          let deleted = imageIdCopy[idx];
                          imageIdCopy.splice(idx, 1);

                          systemAPI.deleteEventImage(deleted).then((res) => {
                            if (res.data.linusCode === 1) {
                              setImage(imageCopy);
                              setImageId(imageIdCopy);
                            } else {
                              alert(res.data.data.msg);
                            }
                          });
                        }}
                      >
                        삭제
                      </Button>
                    </div>
                  ))}
                </ul>
              )}

              <Form.Item>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    htmlType="submit"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/system/event`);
                    }}
                    style={{ margin: "0 10px" }}
                  >
                    목록
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  >
                    저장
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
}
