import client from "./client";

export const getPlayGroups = ({
  page,
  size,
  title,
  content,
  isFestival,
  address,
  startCreatedDate,
  endCreatedDate,
}) =>
  client.get(`/play-group/`, {
    params: {
      page: page,
      size: size,
      title,
      content,
      isFestival,
      address,
      startCreatedDate,
      endCreatedDate,
      isDeleted: 2,
    },
  });

export const getPlayGroup = ({ id }) => client.get(`/play-group/${id}/`);

export const deletePlayGroup = (id) => {
  return client.delete(`/play-group/${id}/`);
};

export const deletePlayGroupImage = (id) => {
  return client.delete(`/play-group-image/${id}/`);
};

/* 페스티벌 관련 */

export const getFestivals = ({
  page,
  size,
  title,
  festivalType,
  startCreatedDate,
  endCreatedDate,
}) =>
  client.get(`/festival/`, {
    params: {
      page,
      size,
      title,
      festivalType,
      startCreatedDate,
      endCreatedDate,
    },
  });

export const getFestival = ({ id }) => client.get(`/festival/${id}/`);

export const deleteFestivals = ({ selectedItems }) =>
  client.delete(`/festival-multi-delete/`, { data: { ids: selectedItems } });

export const createFestival = ({
  country,
  festivalType,
  musicType,
  url,
  isMDPick,
  address,
  addressTitle,
  lat,
  lon,
  startDateTime,
  endDateTime,
  title,
  content,
  cautionContent,
  liveMap,
  images,
  celebrities,
  booths,
  events,
}) =>
  client.post(`/festival/`, {
    country,
    festivalType,
    musicType,
    url,
    isMDPick,
    address,
    addressTitle,
    lat,
    lon,
    startDateTime,
    endDateTime,
    title,
    content,
    cautionContent,
    liveMap,
    images,
    celebrities,
    booths,
    events,
  });

export const editFestival = ({
  id,
  country,
  festivalType,
  musicType,
  url,
  isMDPick,
  address,
  addressTitle,
  lat,
  lon,
  startDateTime,
  endDateTime,
  title,
  content,
  cautionContent,
  liveMap,
  images,
  celebrities,
  booths,
  events,
}) =>
  client.put(`/festival/${id}/`, {
    country,
    festivalType,
    musicType,
    url,
    isMDPick,
    address,
    addressTitle,
    lat,
    lon,
    startDateTime,
    endDateTime,
    title,
    content,
    cautionContent,
    liveMap,
    images,
    celebrities,
    booths,
    events,
  });

export const createCeleb = ({ title, content }) =>
  client.post(`/celebrity/`, {
    title,
    content,
  });

export const editCeleb = ({ id, title, content }) =>
  client.put(`/celebrity/${id}/`, {
    title,
    content,
  });

export const createLiveMap = ({
  title,
  northEastLat,
  northEastLon,
  northWestLat,
  northWestLon,
  southEastLat,
  southEastLon,
  southWestLat,
  southWestLon,
}) =>
  client.post(`/live-map/`, {
    title,
    northEastLat,
    northEastLon,
    northWestLat,
    northWestLon,
    southEastLat,
    southEastLon,
    southWestLat,
    southWestLon,
  });

export const createBooth = ({
  pushTitle,
  pushContent,
  pushReservationTime,
  pushIsEventUser,
  title,
  content,
  cautionContent,
  address,
  addressTitle,
  lat,
  lon,
}) =>
  client.post(`/booth/`, {
    pushTitle,
    pushContent,
    pushReservationTime,
    pushIsEventUser,
    title,
    content,
    cautionContent,
    address,
    addressTitle,
    lat,
    lon,
  });

export const editBooth = ({
  id,
  pushTitle,
  pushContent,
  pushReservationTime,
  pushIsEventUser,
  title,
  content,
  cautionContent,
  address,
  addressTitle,
  lat,
  lon,
}) =>
  client.put(`/booth/${id}/`, {
    pushTitle,
    pushContent,
    pushReservationTime,
    pushIsEventUser,
    title,
    content,
    cautionContent,
    address,
    addressTitle,
    lat,
    lon,
  });

export const saveBoothImage = (id, image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/booth/${id}/image/`, formData);
};

export const saveCeleblImage = (id, image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/celebrity/${id}/image/`, formData);
};

export const saveLiveMapImage = (id, image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/livemap/${id}/image/`, formData);
};

export const saveFestivalImage = (image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/image/`, formData);
};

export const deleteFestivalImage = (id) => {
  return client.delete(`/image/${id}/`);
};

export const saveTimeTableImage = (id, image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/festival/${id}/time-line/`, formData);
};

export const deleteTimeTableImage = (id) => {
  return client.delete(`/festival/${id}/time-line/`);
};

/* 페스티벌 관련 */

export const getPushAlarms = ({
  page,
  size,
  title,
  content,
  category,
  isFestival,
  startCreatedDate,
  endCreatedDate,
}) =>
  client.get(`/push-alarm/`, {
    params: {
      page,
      size,
      title,
      content,
      category,
      isFestival,
      startCreatedDate,
      endCreatedDate,
    },
  });

export const getPushAlarm = ({ id }) => client.get(`/push-alarm/${id}/`);

export const createPushAlarm = ({
  title,
  content,
  reservationTime,
  category,
  isFestivalLikeUser,
  isFestivalPlayGroupUser,
  festival,
}) =>
  client.post(`/push-alarm/`, {
    title,
    content,
    reservationTime,
    category,
    isFestivalLikeUser,
    isFestivalPlayGroupUser,
    festival,
  });

export const editPushAlarm = ({
  id,
  title,
  content,
  reservationTime,
  category,
  isFestivalLikeUser,
  isFestivalPlayGroupUser,
}) =>
  client.put(`/push-alarm/${id}/`, {
    title,
    content,
    reservationTime,
    category,
    isFestivalLikeUser,
    isFestivalPlayGroupUser,
  });

export const deletePushAlarms = ({ selectedItems }) =>
  client.delete(`/pushalarm-multi-delete/`, { data: { ids: selectedItems } });

export const getEvents = ({ page, size, type, pointType }) =>
  client.get(`/event/`, {
    params: {
      page,
      size,
      type,
      pointType,
    },
  });

export const getEventIndex = () => {
  return client.get("/event/?isIndex=1");
};

export const getEvent = ({ id }) => client.get(`/event/${id}/`);

export const createEvent = ({
  index,
  title,
  content,
  startDateTime,
  endDateTime,
  type,
  pointType,
  point,
  url,
  images,
  cautionContent,
}) =>
  client.post(`/event/`, {
    index,
    title,
    content,
    startDateTime,
    endDateTime,
    type,
    pointType,
    point,
    url,
    images,
    cautionContent,
  });

export const editEvent = ({
  id,
  index,
  title,
  content,
  startDateTime,
  endDateTime,
  type,
  pointType,
  point,
  url,
  images,
  isMain,
  cautionContent,
}) =>
  client.put(`/event/${id}/`, {
    index,
    title,
    content,
    startDateTime,
    endDateTime,
    type,
    pointType,
    point,
    url,
    images,
    isMain,
    cautionContent,
  });

export const deleteEvents = ({ selectedItems }) =>
  client.delete(`/event-multi-delete/`, { data: { ids: selectedItems } });

export const saveEventImage = (image) => {
  let formData = new FormData();
  formData.append("image", image);
  return client.post(`/event-image/`, formData);
};

export const deleteEventImage = (id) => {
  return client.delete(`/event-image/${id}/`);
};

export const getPoints = ({ page, size, userEmail, id }) =>
  client.get(`/point/`, {
    params: {
      page,
      size,
      userEmail,
      user: id,
    },
  });

export const getConfigs = () =>
  client.get(`/system-config/`, {
    params: {
      size: 100,
    },
  });

export const editConfigs = ({ id, value }) =>
  client.put(`/system-config/${id}/`, { value });

export const getDropouts = ({ page, size }) =>
  client.get(`/drop-out/`, {
    params: {
      page,
      size,
    },
  });
